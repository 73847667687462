import {createSelector} from 'reselect'
import {setDerivedPropertiesForItem} from '../store/items'
import {RootState, ItemBundle, GriffonItem} from '../store/types'

const getDatabaseContents = (state: RootState) => state.contents
export const getContents = createSelector(
  [getDatabaseContents],
  databaseContents => databaseContents?.map(setDerivedPropertiesForItem),
)

const getSelectedItemId = (state: RootState) => state.selectedItemId
const getMyBundles = (state: RootState) => state.myBundles
const getSelectedBundleId = (state: RootState) => state.selectedBundleId

export const alphabeticalMyBundles = createSelector([getMyBundles], bundles =>
  Object.values(bundles).sort((a, b) =>
    (a.name || '').localeCompare(b.name || ''),
  ),
)

export const getSelectedBundle = createSelector(
  [getSelectedBundleId, getMyBundles],
  (id, bundles) => (id && bundles[id]) || null,
)

export const getMyTags = createSelector([getMyBundles], bundles =>
  Object.values(bundles).filter(b => b.type === 'tag'),
)

export const getMyFaveBundle = createSelector(
  [getMyBundles],
  (bundles): ItemBundle | null =>
    Object.values(bundles).find(b => b.type === 'fave') || null,
)

export const getSelectedBundleItems = createSelector(
  [getSelectedBundle, getContents],
  (bundle, contents) => {
    if (!contents || !bundle) return []
    return bundle.items
      .map(bundleItem => contents.find(i => i.id === bundleItem))
      .filter((i): i is GriffonItem => !!i)
      .sort((i1, i2) => i1.name.localeCompare(i2.name))
  },
)

export const getSelectedItemBundles = createSelector(
  [getSelectedItemId, getMyBundles],
  (itemId, bundles): ItemBundle[] =>
    itemId ? Object.values(bundles).filter(b => b.items.includes(itemId)) : [],
)

export const bundlesById = createSelector([getMyBundles], bundles => bundles)
