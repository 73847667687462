import React from 'react'

import {components as selectComponents} from 'react-select'
import {SelectComponents} from 'react-select/src/components'
import classnames from 'classnames'

import {FiltersOptionType, TextSearchMode} from './types'

const TextSearchModeButton: React.FC<{
  title: string
  onClick: () => void
  selected: boolean
}> = ({title, onClick, selected}) => (
  <span
    style={{
      padding: 4,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: 'small',
      border: '1px solid rgba(0,0,0, 0.7)',
      borderRadius: 4,
      background: selected ? 'rgba(0,0,0, 0.7)' : 'unset',
      color: selected ? 'white' : 'unset',
      margin: '0 5px',
    }}
    onClick={onClick}
  >
    {title}
  </span>
)

export const Control: SelectComponents<FiltersOptionType>['Control'] = ({
  children,
  innerRef,
  ...props
}) => {
  const textSearchMode: TextSearchMode = props.selectProps.textSearchMode
  const setTextSearchMode: (mode: TextSearchMode) => void =
    props.selectProps.setTextSearchMode

  return (
    <div ref={innerRef}>
      <div
        className={classnames(
          'd-lg-none',
          // props.selectProps.menuIsOpen ? 'd-block' : 'd-none',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (props.options[0] as any).__isNew__ ? 'd-block' : 'd-none',
        )}
        style={{overflowX: 'auto', whiteSpace: 'nowrap', padding: 5}}
        onMouseDown={e => {
          // Prevent blurring focus from the input (and hiding menu) when item is clicked
          e.preventDefault()
        }}
      >
        <TextSearchModeButton
          title="Name/Meta"
          onClick={() => setTextSearchMode('text:nameAndMeta')}
          selected={textSearchMode === 'text:nameAndMeta'}
        />
        <TextSearchModeButton
          title="Description"
          onClick={() => setTextSearchMode('text:description')}
          selected={textSearchMode === 'text:description'}
        />
        <TextSearchModeButton
          title="Flavor"
          onClick={() => setTextSearchMode('text:flavour')}
          selected={textSearchMode === 'text:flavour'}
        />
      </div>
      <selectComponents.Control {...props} innerRef={null}>
        {children}
      </selectComponents.Control>
    </div>
  )
}
