import {createSelector} from 'reselect'
import {
  BONUS_SHARING_PLEDGE_CENTS,
  MINIMUM_PLEDGE_CENTS,
  SHARING_PLEDGE_CENTS,
} from '../sharedConstants'
import {ACCESS_CODE_GRANTS, RootState, UserState} from '../store/types'

export const getCurrentUser = (state: RootState): UserState | null =>
  state.auth.user

export const currentUserIsAdmin = createSelector(
  [getCurrentUser],
  currentUser => Boolean(currentUser && currentUser.isAdmin),
)

export const currentUserHasPatreonAccess = createSelector(
  [getCurrentUser],
  currentUser => {
    if (!currentUser) return false

    if (currentUser.patreonEliteHeroTier !== undefined)
      return currentUser.patreonEliteHeroTier

    return currentUser.patreonEntitledAmountCents >= MINIMUM_PLEDGE_CENTS
  },
)

export const currentUserMaxShareSlots = createSelector(
  [getCurrentUser],
  currentUser => {
    if (!currentUser || currentUser.invitedBy) {
      return 0
    }
    if (currentUser.patreonEntitledAmountCents >= BONUS_SHARING_PLEDGE_CENTS) {
      return 6
    }
    if (currentUser.patreonEntitledAmountCents >= SHARING_PLEDGE_CENTS) {
      return 4
    }
    return 0
  },
)

export const currentUserCanShareAccess = createSelector(
  [currentUserMaxShareSlots],
  maxShareSlots => maxShareSlots > 0,
)

export const currentUserHasTotalAccess = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.TOTAL),
    ),
)

export const currentUserHasBook1Access = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.BOOK_1),
    ),
)

export const currentUserHasBook2Access = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.BOOK_2),
    ),
)

export const currentUserCanSeeAllItems = createSelector(
  [currentUserIsAdmin, currentUserHasPatreonAccess, currentUserHasTotalAccess],
  (isAdmin, hasPatreonAccess, hasTotalAccess) =>
    isAdmin || hasPatreonAccess || hasTotalAccess,
)
