import React, {useRef, useLayoutEffect, useState, useEffect} from 'react'
import classnames from 'classnames'

import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'

import {GriffonItem} from '../store/types'

import styles from './FloatingItemNavigationButton.module.scss'

interface Props {
  item: GriffonItem | null
  direction: 'next' | 'previous'
  to: string | null
  forCard: boolean
}

export const FloatingItemNavigationButton: React.FC<Props> = ({
  item,
  direction,
  to,
  forCard,
}) => {
  const [hovering, setHovering] = useState(false)
  const [nameWidth, setNameWidth] = useState(0)
  // on initial render, for the first 100 ms, don't animate any
  // transitions (for clicking through items in sequence)
  const [noAnimate, setNoAnimate] = useState(true)

  const invisibleNameRef = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    if (invisibleNameRef.current) {
      setNameWidth(invisibleNameRef.current.getBoundingClientRect().width)
    }
  }, [setNameWidth])

  useEffect(() => {
    setTimeout(() => {
      setNoAnimate(false)
    }, 100)
  })

  return item && to ? (
    <Link
      replace
      to={to}
      className={classnames(styles.link, styles[direction], {
        [styles.noAnimate]: noAnimate,
        [styles.forCard]: forCard,
      })}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        width: hovering ? 75 * 1.25 + nameWidth : 75,
        height: 75,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* <Square> */}
      <div
        style={{
          width: 75,
          height: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        <FontAwesomeIcon
          fixedWidth
          className={styles.chevron}
          icon={direction === 'next' ? faChevronRight : faChevronLeft}
        />
      </div>
      {/* </Square> */}
      <span
        className={styles.itemname}
        style={{width: hovering ? nameWidth : 0}}
      >
        {item.name}
      </span>
      <span ref={invisibleNameRef} className={styles.itemnameInvisible}>
        {item.name}
      </span>
    </Link>
  ) : null
}
