import React, {useState, useLayoutEffect, useEffect} from 'react'
import classnames from 'classnames'
import {useScrollPosition} from '@n8tb1t/use-scroll-position'

import {LinkContainer} from 'react-router-bootstrap'
import {connect} from 'react-redux'
import {withRouter, RouteComponentProps, Link} from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/free-solid-svg-icons'

import {actions} from '../actions/standard'
import {RootState} from '../store/types'

import Footer from './Footer'
import FullWidthContainer from './LimitedMaxWidthContainer'
import commonStyles from './common.module.scss'
import footerStyles from './Footer.module.scss'
import AccountDetails from './AccountDetails'
import SvgIcon from './SvgIcon'

import utilityStyles from './common.module.scss'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  isNavExpanded: state.ui.navExpanded,
  navExtraVerticalSpace: state.ui.navExtraVerticalSpace,
  accountShowing: state.ui.accountShowing,
})

const dispatchProps = {
  setNavExpanded: actions.uiSetNavExpanded,
  setNavExtraVerticalSpace: actions.uiSetNavExtraVerticalSpace,
  setAccountShowing: actions.uiSetAccountShowing,
}

interface Props extends RouteComponentProps {
  auth: RootState['auth']
  fullWidth?: boolean
  loading?: boolean
  error?: string
  rootClassNames?: string | string[]
  isHomePage?: boolean
  isNavExpanded: boolean
  setNavExpanded: (expanded: boolean) => void
  navExtraVerticalSpace: number
  setNavExtraVerticalSpace: (space: number) => void
  accountShowing: boolean
  setAccountShowing: (showing: boolean) => void
}

const Layout: React.FC<Props> = ({
  location,
  auth,
  children,
  fullWidth,
  loading,
  error,
  rootClassNames,
  isHomePage,
  isNavExpanded,
  setNavExpanded,
  navExtraVerticalSpace,
  setNavExtraVerticalSpace,
  accountShowing,
  setAccountShowing,
}) => {
  const [scrollPercent, setScrollPercent] = useState(0)
  const clampPercent = (n: number) => (n > 1 ? 1 : n < 0 ? 0 : n)

  const targetScroll = -150 // px
  const maxShadowSpread = -16
  const minShadowSpread = -40

  useScrollPosition(
    props => {
      const percent = props.currPos.y / targetScroll // 0 to 1
      setScrollPercent(clampPercent(percent))
    },
    [setScrollPercent, targetScroll],
  )

  useLayoutEffect(() => {
    setScrollPercent(clampPercent(window.scrollY / targetScroll))
  }, [setScrollPercent, targetScroll])

  useLayoutEffect(() => {
    setNavExtraVerticalSpace(0)
  }, [setNavExtraVerticalSpace, location])

  useLayoutEffect(() => {
    setAccountShowing(false)
  }, [setAccountShowing, location])

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--navbar-scroll-percent',
      scrollPercent.toString(),
    )
  }, [scrollPercent])

  const content = auth.isLoggingIn ? (
    <>
      Sent an email to you at{' '}
      <strong>{window.localStorage.getItem('emailForSignIn')}</strong>. Use the
      magic link to sign in to your account.
    </>
  ) : auth.isVerifying ? (
    'Verifying...'
  ) : auth.isLoggingOut ? (
    'Logging out...'
  ) : loading ? (
    'Loading...'
  ) : error ? (
    error
  ) : (
    children
  )

  return (
    <div
      className={classnames(footerStyles.pageContainer, rootClassNames, {
        'nav-expanded': isNavExpanded,
      })}
    >
      <Navbar
        expand="lg"
        sticky="top"
        variant="light"
        className="with-backdrop justify-content-between px-lg-5"
        onToggle={(expanded: boolean) => setNavExpanded(expanded)}
        expanded={isNavExpanded}
      >
        <div
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,

            boxShadow:
              `0px 10px ${-minShadowSpread}px ` +
              `${minShadowSpread +
                (maxShadowSpread - minShadowSpread) * scrollPercent}px ` +
              `rgba(0, 0, 0, 0.5)`,
          }}
        />
        <Navbar.Brand>
          {isHomePage ? (
            scrollPercent >= 1 ? (
              <span
                style={{cursor: 'pointer'}}
                onClick={() => window.scrollTo(0, 0)}
              >
                <span className="d-none d-sm-inline">
                  <Image
                    src="/images/nav-brand-ledgerplus.png"
                    className={utilityStyles.lightOnly}
                    title="Ledger+"
                    height="40"
                  />
                  <Image
                    src="/images/nav-brand-ledgerplus-dark.png"
                    className={utilityStyles.darkOnly}
                    title="Ledger+"
                    height="40"
                  />
                </span>
                <Image
                  src="/images/brand-icon-ledgerplus.png"
                  className="d-sm-none"
                  title="Ledger+"
                  width="50"
                />
              </span>
            ) : (
              <a href="https://thegriffonssaddlebag.com">
                <span className="d-none d-sm-inline">
                  <Image
                    src="/images/nav-brand-saddlebag.png"
                    className={utilityStyles.lightOnly}
                    title="Ledger+"
                    height="40"
                  />
                  <Image
                    src="/images/nav-brand-saddlebag-dark.png"
                    className={utilityStyles.darkOnly}
                    title="Ledger+"
                    height="40"
                  />
                </span>
                <Image
                  src="/images/brand-icon-saddlebag.png"
                  className="d-sm-none"
                  title="The Griffon's Saddlebag"
                  width="50"
                />
              </a>
            )
          ) : (
            <Link to="/">
              <span className="d-none d-sm-inline">
                <Image
                  src="/images/nav-brand-ledgerplus.png"
                  className={utilityStyles.lightOnly}
                  title="Ledger+"
                  height="40"
                />
                <Image
                  src="/images/nav-brand-ledgerplus-dark.png"
                  className={utilityStyles.darkOnly}
                  title="Ledger+"
                  height="40"
                />
              </span>
              <Image
                src="/images/brand-icon-ledgerplus.png"
                className="d-sm-none"
                title="Ledger+"
                width="50"
              />
            </Link>
          )}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <img
            src="/images/bg-mobile-nav-papers.png"
            alt="paper"
            id="mobile-navbar-paper"
            style={{pointerEvents: 'none'}}
            className={utilityStyles.lightOnly}
          />
          <img
            src="/images/bg-mobile-nav-papers-dark.png"
            alt="paper"
            id="mobile-navbar-paper"
            style={{pointerEvents: 'none'}}
            className={utilityStyles.darkOnly}
          />
          <Nav className="ml-auto">
            {auth.user?.isAdmin && (
              <NavDropdown
                id="nav-admin-dropdown"
                title={
                  <Button
                    onClick={() => false}
                    variant="link"
                    className={commonStyles.navBarLink}
                  >
                    <span>Admin</span>
                    <FontAwesomeIcon icon={faCog} />
                  </Button>
                }
              >
                <LinkContainer to="/new" exact>
                  <NavDropdown.Item>
                    <span>Add New Item</span>
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/codes" exact>
                  <NavDropdown.Item>
                    <span>Manage Access Codes</span>
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/foundry" exact>
                  <NavDropdown.Item>
                    <span>Generate Foundry Bundle</span>
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {auth.user && (
              <Nav.Link as="div">
                <LinkContainer to="/stashes" exact>
                  <Button
                    onClick={() => false}
                    variant="link"
                    className={commonStyles.navBarLink}
                  >
                    <span>Stashes</span>
                    <SvgIcon iconName="stashes" />
                  </Button>
                </LinkContainer>
              </Nav.Link>
            )}

            {auth.user ? (
              <Nav.Link as="div">
                <Button
                  onClick={e => {
                    e.preventDefault()
                    setAccountShowing(true)
                  }}
                  variant="link"
                  className={commonStyles.navBarLink}
                >
                  <span>Account</span>
                  <SvgIcon iconName="account" />
                </Button>
              </Nav.Link>
            ) : (
              <Nav.Link as="div">
                <LinkContainer to="/login" exact>
                  <Button
                    onClick={() => false}
                    variant="link"
                    className={classnames(
                      commonStyles.navBarLink,
                      'btn',
                      'btn-link',
                    )}
                  >
                    <span>Log in</span>
                    <div className={commonStyles.navItemAccountIcon} />
                  </Button>
                </LinkContainer>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <div
          style={{
            flexBasis: '100%',
            height: navExtraVerticalSpace,
            display: navExtraVerticalSpace ? undefined : 'none',
          }}
        />
      </Navbar>

      <AccountDetails
        show={accountShowing}
        onHide={() => setAccountShowing(false)}
      />

      <Container
        fluid={fullWidth}
        style={{flexGrow: 1}}
        onClick={() => setNavExpanded(false)}
      >
        {fullWidth ? (
          <FullWidthContainer>{content}</FullWidthContainer>
        ) : (
          content
        )}
      </Container>

      <Footer onClick={() => setNavExpanded(false)} />
    </div>
  )
}

export default withRouter(connect(mapStateToProps, dispatchProps)(Layout))
