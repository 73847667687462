import React, {useState, useRef, useEffect} from 'react'
import TextareaAutosize from 'react-autosize-textarea/lib'

interface Props extends Omit<TextareaAutosize.Props, 'onChange'> {
  onChange: NonNullable<TextareaAutosize.Props['onChange']>
}

const SlightlyRichTextEditor: React.FunctionComponent<Props> = props => {
  const [range, setRange] = useState([0, 0] as [number, number])
  const [usedShortcut, setUsedShortcut] = useState(
    null as null | {range: [number, number]; marker: string},
  )
  useEffect(() => {
    const textArea = textAreaRef.current
    if (usedShortcut && textArea) {
      textArea.selectionStart =
        usedShortcut.range[0] + usedShortcut.marker.length
      textArea.selectionEnd = usedShortcut.range[1] + usedShortcut.marker.length
      setUsedShortcut(null)
    }
  }, [usedShortcut])
  const textAreaRef = useRef(null as null | HTMLTextAreaElement)

  const {onChange} = props

  return (
    <TextareaAutosize
      ref={textAreaRef}
      {...props}
      onSelect={event => {
        const input = event.currentTarget
        setRange([input.selectionStart, input.selectionEnd])
      }}
      onKeyPress={event => {
        if (
          (event.key === 'i' || event.key === 'b') &&
          (event.metaKey || event.ctrlKey)
        ) {
          event.preventDefault()

          if (range[0] === range[1]) return

          // emphasis/strong
          const marker = event.key === 'i' ? '_' : event.key === 'b' ? '**' : ''

          const input = event.currentTarget
          const str = input.value

          const [start, selection, end] = [
            str.substring(0, range[0]),
            str.substring(range[0], range[1]),
            str.substring(range[1]),
          ]

          const newValue = start + `${marker}${selection}${marker}` + end
          // make a fake onChange event
          const newEvent = {
            ...event,
            target: {...event.target, value: newValue},
          }

          setUsedShortcut({range, marker})
          onChange(newEvent)
          // TODO: try document.insertText
        }
      }}
    />
  )
}

export default SlightlyRichTextEditor
