import React from 'react'

import {Markdown} from 'react-showdown'
import {showdownOptions} from '../markdown'

import styles from './ItemDescription.module.scss'

const ItemDescription: React.FC<{
  markup: string
  flavour?: boolean
  forCard?: boolean
  omitStyle?: boolean
}> = props => (
  <div
    className={
      props.omitStyle
        ? undefined
        : [
            styles.itemdescription,
            props.flavour && styles.flavourpreview,
            props.forCard && styles.forCard,
          ].join(' ')
    }
  >
    <Markdown markdown={props.markup} options={showdownOptions} />
  </div>
)

export default ItemDescription
