import React, {useState} from 'react'

import {withRouter, RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux'
import classnames from 'classnames'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {StoreDispatch} from '../store'
import {ItemBundle, RootState} from '../store/types'
import {alphabeticalMyBundles} from '../selectors'
import {createBundleWithName} from '../actions/thunks'

import ScrollToTopOnMount from './ScrollToTopOnMount'
import Layout from './Layout'
import {
  StashPreview,
  NewStashButton,
  PlaceholderStash,
} from './stashes/StashPreview'
import Dialog from './Dialog'
import useStashManagement from './stashes/useStashManagement'

import commonStyles from './common.module.scss'

interface Props extends RouteComponentProps<{bundleId: string}> {
  myBundles: ItemBundle[]
  createBundle: (name: string) => Promise<ItemBundle | null>
  dispatch: StoreDispatch
}

const mapStateToProps = (state: RootState) => ({
  myBundles: alphabeticalMyBundles(state),
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createBundle: (name: string) => dispatch(createBundleWithName(name)),
  dispatch,
})

const BundleView: React.FC<Props> = props => {
  const {myBundles, history, createBundle, dispatch} = props
  const [loading] = useState(false)
  const [adding, setAdding] = useState(false)

  const {
    render: renderManagementDialogs,
    deleteStash,
    duplicateStash,
    renameStash,
  } = useStashManagement(dispatch, {})

  const navigateToStash = (stash: ItemBundle) =>
    history.push(`/stashes/${stash.id}`)

  return (
    <Layout loading={loading} rootClassNames={commonStyles.headerWashStashes}>
      <ScrollToTopOnMount />
      <img
        alt="Stashes"
        src="/images/page-header-stashes.png"
        className={classnames(
          commonStyles.pageHeader,
          commonStyles.pageHeaderImg,
        )}
      />

      {renderManagementDialogs()}

      <Dialog
        prompt
        title="Name Stash"
        submitTitle="Done"
        show={adding}
        exit={() => setAdding(false)}
        onSubmit={async value => {
          setAdding(false)
          if (value) {
            const stash = await createBundle(value)
            if (stash) history.push(`/stashes/${stash.id}`)
          }
        }}
      />

      <Row>
        {myBundles.map(b => (
          <Col lg={3} md={4} xs={6} key={b.id}>
            <div style={{display: 'flex', justifyContent: 'stretch'}}>
              <StashPreview
                editable
                stash={b}
                onClick={navigateToStash}
                onClickDelete={() => deleteStash(b)}
                onClickDuplicate={() => duplicateStash(b)}
                onClickRename={() => renameStash(b)}
              />
            </div>
          </Col>
        ))}
        {adding && (
          <Col lg={3} md={4} xs={6}>
            <PlaceholderStash />
          </Col>
        )}
        <Col lg={3} md={4} xs={6}>
          <NewStashButton onClick={() => setAdding(true)} />
        </Col>
      </Row>
    </Layout>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BundleView))
