import React, {useRef, useState} from 'react'
import {MapStateToProps, connect} from 'react-redux'

import {ItemBundle, RootState, GriffonItem} from '../../store/types'
import {itemsById} from '../../selectors'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons'

import ImageDisplay from '../ImageDisplay'
import StashThumbnailContainer from './StashThumbnailContainer'
import StashMenuTooltip, {StashMenuProps} from './StashMenuTooltip'

import styles from './StashPreview.module.scss'

interface OwnProps extends StashMenuProps {
  stash: ItemBundle
  editable?: boolean
}

interface StateProps extends StashMenuProps {
  items: Array<GriffonItem | null>
  editable?: boolean
}

const ROW_COUNT = 2
const ITEM_COUNT = Math.pow(ROW_COUNT, 2)

const CornerThing: React.FC<StashMenuProps> = props => {
  const menuTarget = useRef<HTMLDivElement>(null)
  const [showingMenu, toggleMenu] = useState(false)

  return (
    <div className={styles.CornerThing}>
      <div className={styles.CornerThing__triangle} />
      {menuTarget?.current && (
        <StashMenuTooltip
          show={showingMenu}
          onHide={() => toggleMenu(false)}
          target={menuTarget.current}
          onClickDelete={props.onClickDelete}
          onClickRename={props.onClickRename}
          onClickDuplicate={props.onClickDuplicate}
        />
      )}
      <div
        ref={menuTarget}
        onClick={e => {
          toggleMenu(true)
          e.stopPropagation()
        }}
        className={styles.CornerThing__ellipsiscontainer}
      >
        <FontAwesomeIcon icon={faEllipsisH} inverse />
      </div>
    </div>
  )
}

const StashThumbnail: React.FC<StateProps> = props => {
  return (
    <StashThumbnailContainer>
      <CornerThing
        onClickDelete={props.onClickDelete}
        onClickDuplicate={props.onClickDuplicate}
        onClickRename={props.onClickRename}
      />
      {props.items.map((item, index) => (
        <div key={index} className={styles.StashThumbnail__image}>
          <ImageDisplay
            fullWidth
            thumbnail={false}
            size="small"
            imageId={item?.imageIds?.[0] || ''}
          />
        </div>
      ))}
    </StashThumbnailContainer>
  )
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state,
  ownProps,
) => {
  const {stash, ...stateProps} = ownProps
  const items: Array<GriffonItem | null> = []
  for (let i = 0; i < ITEM_COUNT; i++) {
    items.push(null)
  }

  let actualItemCount = 0
  for (const itemId of ownProps.stash.items) {
    items[actualItemCount] = itemsById(state)[itemId]
    actualItemCount += 1
    if (actualItemCount === ITEM_COUNT) break
  }

  return {...stateProps, items}
}

export default connect(mapStateToProps)(StashThumbnail)
