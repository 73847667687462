import React from 'react'
import {connect} from 'react-redux'

import {RootState} from '../store/types'
import {
  currentUserHasPatreonAccess,
  currentUserHasBook1Access,
  currentUserHasBook2Access,
  currentUserHasTotalAccess,
  currentUserIsAdmin,
} from '../selectors'
import {patreonLinkProps, accessCodeLinkProps} from './externalLinks'

interface Props {
  visibleItemCount: number
  totalItemCount: number
  patreonUnlocked: boolean
  accessCodeUnlocked: boolean
  isAdmin: boolean
}

const PatreonLink = () => <a {...patreonLinkProps}>Patreon</a>
const AccessCodeLink = () => <a {...accessCodeLinkProps}>Access Code</a>

const ItemListFooter: React.FC<Props> = ({
  totalItemCount,
  visibleItemCount,
  patreonUnlocked,
  accessCodeUnlocked,
  isAdmin,
}) => {
  const itemCountDescription =
    totalItemCount === visibleItemCount
      ? `all ${totalItemCount}`
      : `${visibleItemCount} of ${totalItemCount}`
  return (
    <div className="text-center mt-3">
      <p className="font-italic text-muted">
        Displaying {itemCountDescription} items.
      </p>
      {patreonUnlocked ? (
        <p>
          Thank you so much for your support through <PatreonLink />, hero!
        </p>
      ) : isAdmin ? null : accessCodeUnlocked ? (
        <p>
          Unlock all items (and more) through <PatreonLink />.
        </p>
      ) : (
        <p>
          Unlock all items (and more) through <PatreonLink />, or get the first
          365 forever with an <AccessCodeLink />.
        </p>
      )}
    </div>
  )
}

export default connect(
  (state: RootState): Props => ({
    totalItemCount: state.totalItemCount,
    visibleItemCount: state.contents?.length || 0,
    isAdmin: currentUserIsAdmin(state),
    patreonUnlocked: currentUserHasPatreonAccess(state),
    accessCodeUnlocked:
      currentUserHasBook1Access(state) ||
      currentUserHasBook2Access(state) ||
      currentUserHasTotalAccess(state),
  }),
)(ItemListFooter)
