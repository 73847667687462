import React from 'react'
import {components as selectComponents} from 'react-select'

import {FiltersOptionType} from './types'

export const MultiValueLabel: React.FC<{data: FiltersOptionType}> = props => {
  const data: FiltersOptionType = props.data
  let label: React.ReactNode = data.label
  let labelPrefix: string | undefined = undefined
  if (data.type === 'itemType') labelPrefix = 'Type'
  else if (data.type === 'rarity') labelPrefix = 'Rarity'
  else if (data.type === 'significance') labelPrefix = 'Subrarity'
  else if (data.type === 'text:nameAndMeta') labelPrefix = 'Name/Meta'
  else if (data.type === 'text:description') labelPrefix = 'Description'
  else if (data.type === 'text:flavour') labelPrefix = 'Flavor'
  else if (data.type === 'cardVolume') labelPrefix = 'Card Vol.'
  else if (data.type === 'bookVolume') labelPrefix = 'Book'

  // TODO: determine why data.label doesn't use the labels from the Select choices array
  let valueLabel = data.label
  if (data.type === 'rarity' && data.value === '__multiple_rarities__')
    valueLabel = 'varies'

  if (labelPrefix) {
    label = (
      <>
        <small>
          <strong>{labelPrefix.toUpperCase()}</strong>
        </small>{' '}
        | {valueLabel}
      </>
    )
  }
  const newProps = {...props, children: label}
  return <selectComponents.MultiValueLabel {...newProps} />
}
