import React, {useState} from 'react'
import {DateTime} from 'luxon'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import Layout from '../Layout'
import {useSelector} from 'react-redux'
import {getContents} from '../../selectors'
import {GriffonItem} from '../../store/types'
import ImageDisplay from '../ImageDisplay'
import exportFoundryItems from './exportFoundryItems'

type YearMonth = [number, number]

const MonthSelector: React.FC<{
  value: YearMonth
  onChange: (v: YearMonth) => void
}> = ({onChange, value: [year, month]}) => {
  const now = DateTime.local()
  const startingYear = 2018
  const years = [...Array(now.year - startingYear + 1).keys()].map(
    n => startingYear + n,
  )
  const months = [...Array(12).keys()].map(n => n + 1)

  return (
    <Form.Group>
      <Row>
        <Col>
          <Form.Control
            as="select"
            value={year}
            onChange={event =>
              onChange([parseInt(event.currentTarget.value), month])
            }
          >
            {years.map(y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Form.Control
            as="select"
            value={month}
            onChange={event =>
              onChange([year, parseInt(event.currentTarget.value)])
            }
          >
            {months.map(m => (
              <option key={m} value={m}>
                {DateTime.local(now.year, m).monthLong}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  )
}

const MonthPreview: React.FC<{items: GriffonItem[]; yearMonth: YearMonth}> = ({
  items,
  yearMonth: [year, month],
}) => {
  const dateString = `${year}-${('' + month).padStart(2, '0')}`

  return items.length === 0 ? (
    <>No items for this month</>
  ) : (
    <div>
      <Form.Group>
        <Button
          onClick={() =>
            exportFoundryItems(
              items,
              `GriffonsSaddlebag_FoundryAssets_${dateString}`,
            )
          }
        >
          Export {items.length} items
        </Button>
      </Form.Group>

      <Table size="sm" borderless>
        <thead>
          <tr>
            <th style={{width: '1%'}}></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td>
                <ImageDisplay
                  thumbnail={false}
                  imageId={item.imageIds?.[0] || ''}
                  size="tiny"
                  dimension={33}
                />
              </td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const FoundryBundle: React.FC = () => {
  const now = DateTime.local()
  const [[year, month], setYearMonth] = useState<YearMonth>([
    now.year,
    now.month,
  ])

  const allItems = useSelector(getContents)
  const filteredItems = (allItems || []).filter(
    item => item.dateMonth === month - 1 && item.dateYear === year,
  )

  return (
    <Layout>
      <h4>Export items for Foundry</h4>
      <MonthSelector value={[year, month]} onChange={setYearMonth} />
      <MonthPreview items={filteredItems} yearMonth={[year, month]} />
    </Layout>
  )
}

export default FoundryBundle
