import {createReducer} from 'typesafe-actions'
import {actions} from '../actions/standard'
import * as types from '../store/types'

export const initialState: types.ListState = {
  identifier: 'home',
  filters: {
    attunementType: [],
    itemSubtype: [],
    itemType: [],
    tagSearch: [],
  },
  sortColumn: 'name',
  sortDirection: types.SORT_ASC,
  visibleSecondaryColumn: 'type',
  dateColumnMode: 'date',
}

type ListAction = types.RootAction & {
  meta: {listName: types.ListIdentifier}
}

const stateIfActionDoesNotMatchList = (
  state: types.ListState,
  action: ListAction,
) => (action.meta.listName === state.identifier ? null : state)

export default createReducer(initialState)
  .handleAction(
    actions.updateFilterState,
    (state, action) =>
      stateIfActionDoesNotMatchList(state, action) ?? {
        ...state,
        filters: {...state.filters, ...action.payload},
      },
  )
  .handleAction(actions.uiToggleSortColumn, (state, action) => {
    let {sortColumn, sortDirection} = state
    const chosenColumn = action.payload

    sortDirection =
      sortColumn === chosenColumn
        ? ((sortDirection * -1) as types.SortDirection)
        : 1
    sortColumn = chosenColumn

    return (
      stateIfActionDoesNotMatchList(state, action) ?? {
        ...state,
        sortColumn,
        sortDirection,
      }
    )
  })
  .handleAction(
    actions.uiSetVisibleSecondaryColumn,
    (state, action) =>
      stateIfActionDoesNotMatchList(state, action) ?? {
        ...state,
        visibleSecondaryColumn: action.payload,
      },
  )
  .handleAction(
    actions.uiSetDateColumnMode,
    (state, action) =>
      stateIfActionDoesNotMatchList(state, action) ?? {
        ...state,
        dateColumnMode: action.payload,
      },
  )
