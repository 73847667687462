import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {OverlayTriggerProps} from 'react-bootstrap/esm/OverlayTrigger'
import commonStyles from './common.module.scss'

const TOOLTIP_TIMEOUT = 2000

export function ClickOverlayTrigger({
  children,
  ...props
}: Omit<OverlayTriggerProps, 'trigger' | 'show'>) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false)
      }, TOOLTIP_TIMEOUT)

      return () => clearTimeout(timeout)
    }
  })

  return (
    <OverlayTrigger show={show} {...props}>
      <button
        className={commonStyles.unstyledButton}
        onBlur={() => setShow(false)}
        onClick={() => setShow(true)}
      >
        {children}
      </button>
    </OverlayTrigger>
  )
}
