import {table} from 'table'
import {combinations} from './generateCombinations'
import {LootTable, SortingHatItem, Rarity, Subrarity} from './items'

type ItemWeightingProperty =
  | 'consumable'
  | 'cursed'
  | 'minor'
  | 'nonattunement'
  | 'major_or_attunement'

type ItemSizeMap = Partial<Record<ItemWeightingProperty, number>>

type Predicate<T> = (arg0: T) => boolean
const not = <T>(fn: Predicate<T>) => (arg0: T) => !fn(arg0)
const isMajor = (item: SortingHatItem): boolean => item.significance === 'major'

const shorthandRarity = (rarity: Rarity) =>
  ({
    uncommon: 'U',
    common: 'C',
    rare: 'R',
    'very rare': 'V',
    artifact: 'A',
    legendary: 'L',
  }[rarity])

export interface SortingHat {
  distribute(): LootTable
  output(): string
  readonly dieSize: number
}

abstract class BaseSortingHat implements SortingHat {
  protected readonly items: SortingHatItem[]

  constructor(items: SortingHatItem[]) {
    this.items = items
  }

  distribute(): LootTable {
    throw new Error('Unimplemented')
    // return []
  }

  get dieSize() {
    return 0
  }

  /**
   * All included rarities, in order of increasing rarity
   */
  get rarities(): Rarity[] {
    const raritySet = new Set(this.items.map(i => i.rarity))
    const allRarities: Rarity[] = [
      'common',
      'uncommon',
      'rare',
      'very rare',
      'legendary',
      'artifact',
    ]
    return allRarities.filter(r => raritySet.has(r))
  }

  get subrarities(): Subrarity[] {
    const subrarities: Subrarity[] = []
    if (this.items.some(not(isMajor))) subrarities.push('minor')
    if (this.items.some(isMajor)) subrarities.push('major')
    return subrarities
  }

  output(): string {
    let rowStart = 1
    const distributed = this.distribute()
    return (
      table([
        [
          `d${this.dieSize}`,
          'Rarity',
          'Sub',
          'Attu.',
          'Curse',
          'Consum.',
          'Name',
        ],
        ...distributed.map(row => {
          const rowEnd = rowStart + row.size - 1
          const nameLabel = `${row.item.name}`
          const numLabel =
            rowStart === rowEnd ? `${rowEnd}` : `${rowStart}–${rowEnd}`
          rowStart = rowEnd + 1
          return [
            numLabel,
            shorthandRarity(row.item.rarity),
            row.item.significance === 'major' ? 'M' : 'm',
            row.item.attunement ? 'a' : '',
            row.item.cursed ? 'X' : '',
            row.item.consumable ? 'c' : '',
            nameLabel,
          ]
        }),
      ]) +
      '\n' +
      table([
        this.rarities,
        this.rarities.map(r =>
          distributed.reduce(
            (sum, row) => sum + (row.item.rarity === r ? row.size : 0),
            0,
          ),
        ),
      ])
    )
  }
}

export class SimpleHat extends BaseSortingHat {
  get dieSize(): number {
    return this.items.length
  }

  distribute(): LootTable {
    return this.items.map(item => ({
      item,
      size: 1,
    }))
  }
}

type ItemRarityGroup = {
  rarity: Rarity
  // size: number
  items: SortingHatItem[]
  estimatedItemSize: number
  remainder?: number
  itemSizeMap?: ItemSizeMap
}

export class WeightedHat extends BaseSortingHat {
  rarityWeights: Record<Rarity, number> = {
    artifact: 1,
    legendary: 2,
    'very rare': 4,
    rare: 8,
    uncommon: 16,
    common: 32,
  }

  readonly weightProperties: ItemWeightingProperty[] = [
    'major_or_attunement',
    'nonattunement',
    'minor',
    'cursed',
    'consumable',
  ]

  fixedDieSize = 100

  get dieSize(): number {
    return this.fixedDieSize
  }

  output(): string {
    return super.output()
  }

  groupSize(group: ItemRarityGroup): number {
    if (!group.itemSizeMap)
      return (
        group.estimatedItemSize * group.items.length + (group.remainder ?? 0)
      )

    let size = 0
    for (const item of group.items) {
      const prop = this.itemWeightingProperty(item)
      const itemSize = group.itemSizeMap[prop]
      if (!itemSize) throw new Error(`missing property ${prop} in itemSizeMap`)
      size += itemSize
    }
    return size
  }

  sizeForItemInGroup(item: SortingHatItem, group: ItemRarityGroup): number {
    if (!group.itemSizeMap) return group.estimatedItemSize

    const prop = this.itemWeightingProperty(item)
    const itemSize = group.itemSizeMap[prop]
    if (!itemSize) throw new Error(`missing property ${prop} in itemSizeMap`)
    return itemSize
  }

  itemWeightingProperty(item: SortingHatItem): ItemWeightingProperty {
    if (item.consumable) {
      return 'consumable'
    } else if (item.cursed) {
      return 'cursed'
    } else if (item.significance !== 'major') {
      return 'minor'
    } else if (!item.attunement) {
      return 'nonattunement'
    } else {
      return 'major_or_attunement'
    }
  }

  /**
   *
   * @param initialItemSize
   * @param properties
   * @returns A list of combinations of distinct integers
   *          centered around `initialItemSize`. Each combination
   *          has the same length as `properties` has.
   */
  generateCombos(
    initialItemSize: number,
    properties: ItemWeightingProperty[],
  ): Array<ItemSizeMap> {
    const sizeCandidates: number[] = []
    const minSize = Math.max(1, initialItemSize - 2 * properties.length)
    const maxSize = initialItemSize + 2 * properties.length
    console.debug({
      initialItemSize,
      minSize,
      maxSize,
      length: properties.length,
    })
    for (let i = minSize; i <= maxSize; i++) {
      sizeCandidates.push(i)
    }

    return Array.from(combinations(sizeCandidates, properties.length)).map(
      sizes => {
        const combo: ItemSizeMap = {}
        for (const [index, prop] of properties.entries()) {
          combo[prop] = sizes[index]
        }
        return combo
      },
    )
  }

  /**
   * Set values for consumable/minor/attunement items
   * within a rarity group
   */
  organizeRarityGroup(
    group: ItemRarityGroup,
    fixedSize?: number,
  ): ItemRarityGroup {
    console.debug(`=======organizing ${group.rarity}=======`)

    // no more can be done
    if (fixedSize && group.estimatedItemSize === 1) return group

    const propertyCounts: Record<ItemWeightingProperty, number> = {
      consumable: 0,
      cursed: 0,
      minor: 0,
      nonattunement: 0,
      major_or_attunement: 0,
    }

    for (const item of group.items) {
      propertyCounts[this.itemWeightingProperty(item)] += 1
    }

    const initialSize = this.groupSize(group)

    const combos = this.generateCombos(
      initialSize / group.items.length,
      this.weightProperties.filter(p => propertyCounts[p] > 0),
    )
    console.debug({combos, rarity: group.rarity})

    const {delta, ...bestCombo} = combos
      .map(combo => {
        const delta =
          this.groupSize({...group, itemSizeMap: combo}) - initialSize

        return {
          ...combo,
          delta,
        }
      })
      .sort((c1, c2) => {
        const c1Sign = c1.delta > 0 ? 1 : c1.delta < 0 ? -1 : 0
        const c2Sign = c2.delta > 0 ? 1 : c2.delta < 0 ? -1 : 0
        const signComp = c1Sign - c2Sign

        if (signComp !== 0) return signComp

        return Math.abs(c1.delta) - Math.abs(c2.delta)
      })[0]

    const resizedGroup = {...group, itemSizeMap: bestCombo}
    const newSize = this.groupSize(resizedGroup)

    console.debug({
      initialSize,
      bestCombo,
      newSize,
      delta,
    })

    return resizedGroup
  }

  /**
   * Return item groups with whole number values
   * @param dieSize Desired die size, i.e. total of all values
   */
  tidyRarityGroups(
    dieSize: number,
    groups: ItemRarityGroup[],
  ): ItemRarityGroup[] {
    if (groups.length === 1) {
      const [group] = groups
      const itemSize = Math.floor(dieSize / group.items.length)
      const organized = this.organizeRarityGroup(
        {
          ...group,
          estimatedItemSize: itemSize,
        },
        dieSize,
      )

      const remainder = dieSize - this.groupSize(organized)
      console.debug({remainder, organized})
      if (remainder < 0) {
        throw new Error('group size too big for die size')
      }

      return [{...organized, remainder}]
    }

    const groupsByDecreasingSensitivity = groups.sort(
      (g1, g2) =>
        this.rarities.indexOf(g2.rarity) - this.rarities.indexOf(g1.rarity),
    )

    const mostSensitiveGroup = groupsByDecreasingSensitivity[0]
    if (!mostSensitiveGroup) return []

    const itemSize =
      mostSensitiveGroup.estimatedItemSize < 1
        ? 1
        : Math.round(mostSensitiveGroup.estimatedItemSize)

    const tidiedGroup: ItemRarityGroup = this.organizeRarityGroup({
      ...mostSensitiveGroup,
      estimatedItemSize: itemSize,
    })

    const remainingDieSize = dieSize - this.groupSize(tidiedGroup)

    return [
      tidiedGroup,
      ...this.tidyRarityGroups(
        remainingDieSize,
        groupsByDecreasingSensitivity.slice(1),
      ),
    ]
  }

  ROUGH_ITEM_SIZE_BY_PROPERTY: Record<ItemWeightingProperty, number> = {
    consumable: 5,
    cursed: 4,
    minor: 3,
    nonattunement: 2,
    major_or_attunement: 1,
  }

  distribute(): LootTable {
    for (const dieSize of [100, 200, 400, 800, 1000]) {
      try {
        this.fixedDieSize = dieSize
        const result = this._distribute()

        // See how far the rarity ratios drifted
        if (this.rarities.length > 1) {
          const resultRaritySizes = this.rarities
            .map(r => {
              return {
                rarity: r,
                size: result.reduce(
                  (sum, row) => sum + (row.item.rarity === r ? row.size : 0),
                  0,
                ),
              }
            })
            .reverse()

          const ratioDeltas: number[] = []
          for (let i = 0; i < resultRaritySizes.length - 1; i++) {
            const firstRarity = resultRaritySizes[i]
            const nextRarity = resultRaritySizes[i + 1]
            const expectedRatio =
              this.rarityWeights[firstRarity.rarity] /
              this.rarityWeights[nextRarity.rarity]
            const resultRatio = firstRarity.size / nextRarity.size
            ratioDeltas.push(expectedRatio - resultRatio)
            console.debug(
              `Expected ratio for ${firstRarity.rarity}<->${nextRarity.rarity}: ${expectedRatio}. Actual: ${resultRatio}`,
            )
          }

          const totalRatioDelta = ratioDeltas.reduce(
            (sum, delta) => sum + Math.abs(delta),
            0,
          )

          console.debug({
            ratioDeltas,
            totalRatioDelta,
          })

          if (totalRatioDelta > 0.5)
            throw new Error('Too much variance in expected rarity ratios')
        }

        return result
      } catch (e) {
        // console.error(e)
        console.debug(
          `unable to compute using d${dieSize}`,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e as any).toString(),
        )
      }
    }

    throw new Error('Uncomputable!')
  }

  _distribute(): LootTable {
    const rarities = this.rarities
    const raritiesTotalWeight = rarities.reduce(
      (sum, r) => sum + this.rarityWeights[r],
      0,
    )
    const scalingFactor = this.fixedDieSize / raritiesTotalWeight
    const scaledRarityGroups = rarities.map<ItemRarityGroup>(r => {
      const items = this.items.filter(i => i.rarity === r)
      const size = scalingFactor * this.rarityWeights[r]
      return {
        rarity: r,
        items,
        itemSize: size / items.length,
        estimatedItemSize: size / items.length,
      }
    })

    const items: LootTable = []

    console.debug({scaledRarityGroups})

    const tidyGroups = this.tidyRarityGroups(this.dieSize, scaledRarityGroups)
    for (const rarity of this.rarities) {
      const group = tidyGroups.find(g => g.rarity === rarity)
      if (!group) throw new Error(`Rarity ${rarity} missing in tidyGroups`)
      let remainder = group.remainder || 0

      const orderedItems = group.items
        .sort((i1, i2) => {
          const weightOrder =
            this.weightProperties.indexOf(this.itemWeightingProperty(i2)) -
            this.weightProperties.indexOf(this.itemWeightingProperty(i1))

          if (weightOrder === 0) return i1.name > i2.name ? 1 : -1
          else return weightOrder
        })
        .map(item => ({item, size: this.sizeForItemInGroup(item, group)}))

      let itemIndex = 0
      while (remainder > 0) {
        const item = orderedItems[itemIndex % orderedItems.length]
        // console.debug({itemIndex, item, remainder})
        item.size += 1

        itemIndex += 1
        remainder -= 1
      }

      items.push(...orderedItems)
    }

    return items
  }
}

export class SingleRarityHat extends BaseSortingHat {
  private generateCombos(
    properties: ItemWeightingProperty[],
    totalNumberOfItems: number,
  ): Array<ItemSizeMap> {
    let sizeCandidates = [1, 2, 3, 4, 5, 6, 7, 8]

    // For small tables, increase the size of weights
    // in order to (approximately) reach at least around 100
    if (totalNumberOfItems <= 4)
      sizeCandidates = sizeCandidates.map(s => s * 20)
    else if (totalNumberOfItems <= 8)
      sizeCandidates = sizeCandidates.map(s => s * 10)
    else if (totalNumberOfItems <= 12)
      sizeCandidates = sizeCandidates.map(s => s * 5)
    else if (totalNumberOfItems <= 20)
      sizeCandidates = sizeCandidates.map(s => s * 2)

    return Array.from(combinations(sizeCandidates, properties.length)).map(
      sizes => {
        const combo: ItemSizeMap = {}
        for (const [index, prop] of properties.entries()) {
          combo[prop] = sizes[index]
        }
        return combo
      },
    )
  }

  private get itemsWithWeightingProperties(): Array<{
    item: SortingHatItem
    weightingProperty: ItemWeightingProperty
  }> {
    return this.items.map(item => {
      const weightingProperty: ItemWeightingProperty = item.consumable
        ? 'consumable'
        : item.cursed
        ? 'cursed'
        : item.attunement
        ? 'major_or_attunement'
        : item.significance === 'major'
        ? 'nonattunement'
        : 'minor'

      return {item, weightingProperty}
    })
  }

  /**
   * Ordered weighting properties present in the item list
   */
  private get weightingProperties(): ItemWeightingProperty[] {
    const items = this.itemsWithWeightingProperties
    const itemWeightingPropertiesSet = new Set(
      items.map(({weightingProperty}) => weightingProperty),
    )
    const orderedProperties: ItemWeightingProperty[] = [
      'major_or_attunement',
      'nonattunement',
      'minor',
      'cursed',
      'consumable',
    ]

    return orderedProperties.filter(p => itemWeightingPropertiesSet.has(p))
  }

  private nudgeSizesForRemainder(groups: LootTable[], remainder: number): void {
    if (remainder === 0) return

    const bestGroupToAdjust =
      groups.find(group => {
        if (remainder > 0) return group.length >= remainder
        else return group[0].size * group.length >= Math.abs(remainder)
      }) || groups[0]
    const reverseOrder = remainder < 0

    if (reverseOrder) {
      bestGroupToAdjust.reverse()
    }

    let index = 0

    while (remainder !== 0) {
      const itemToAdjust = bestGroupToAdjust[index % bestGroupToAdjust.length]

      index++
      if (remainder > 0) {
        itemToAdjust.size++
        remainder--
      } else {
        itemToAdjust.size--
        remainder++
      }
    }

    if (reverseOrder) {
      bestGroupToAdjust.reverse()
    }
  }

  distribute(): LootTable {
    const itemsWithWeightingProperties = this.itemsWithWeightingProperties
    const countOfWeightingProperties = itemsWithWeightingProperties.reduce(
      (counts, {weightingProperty}) => {
        const currentCount = counts[weightingProperty] ?? 0

        return {...counts, [weightingProperty]: currentCount + 1}
      },
      {} as Record<ItemWeightingProperty, number>,
    )
    const weightingProperties = this.weightingProperties

    const combinations = this.generateCombos(
      weightingProperties,
      this.items.length,
    )

    let bestComboMetadata: {
      offBy: number
      nearTo: number
      combo: Partial<Record<ItemWeightingProperty, number>>
    } | null = null

    for (const combo of combinations) {
      const totalSize = Object.entries(combo).reduce((sum, [prop, weight]) => {
        if (weight === undefined) return sum
        return (
          sum +
          weight * countOfWeightingProperties[prop as ItemWeightingProperty]
        )
      }, 0)

      const nearTo =
        totalSize < 150
          ? 100
          : totalSize < 250
          ? 200
          : totalSize < 300
          ? 400
          : totalSize < 500
          ? 600
          : totalSize < 700
          ? 800
          : 1000

      const offBy = nearTo - totalSize

      const comboMetadata = {totalSize, combo, nearTo, offBy}

      if (
        bestComboMetadata === null ||
        Math.abs(offBy) < Math.abs(bestComboMetadata.offBy)
      ) {
        bestComboMetadata = comboMetadata
      }

      // console.log({totalSize, combo})
    }

    // console.log(bestComboMetadata)

    if (bestComboMetadata === null)
      throw new Error('Could not compute distribution')
    const bestCombo = bestComboMetadata.combo

    const groups: LootTable[] = weightingProperties
      .slice()
      .reverse()
      // .flatMap(prop => {
      .map(prop => {
        return itemsWithWeightingProperties
          .filter(i => i.weightingProperty === prop)
          .map(i => ({
            item: i.item,
            size: bestCombo[prop] ?? 1,
          }))
          .sort((i1, i2) => i1.item.name.localeCompare(i2.item.name))
      })

    this.nudgeSizesForRemainder(groups, bestComboMetadata.offBy)

    return groups.flat()
  }
}
