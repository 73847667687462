import React from 'react'
import {connect} from 'react-redux'

import Button from 'react-bootstrap/Button'

import {
  currentUserHasBook1Access,
  currentUserHasBook2Access,
  currentUserHasPatreonAccess,
  currentUserHasTotalAccess,
  currentUserIsAdmin,
} from '../selectors'
import {SHARING_PLEDGE_CENTS} from '../sharedConstants'
import {RootState} from '../store/types'

import styles from './UpgradeCTA.module.scss'
import {accessCodeLinkProps, patreonLinkProps} from './externalLinks'
import {patronTitleForPledgeCents} from '../patreon'

interface Props {
  totalItemCount: number
  visible: boolean
}

const mapStateToProps = (state: RootState): Props => ({
  totalItemCount: state.totalItemCount,
  visible:
    !currentUserIsAdmin(state) &&
    !currentUserHasTotalAccess(state) &&
    !currentUserHasBook1Access(state) &&
    !currentUserHasBook2Access(state) &&
    !currentUserHasPatreonAccess(state),
})

const UpgradeCTA: React.FC<Props> = ({totalItemCount, visible}) => {
  if (!visible) return null
  return (
    <>
      <p className="text-center">
        <strong>You&apos;re only viewing free items.</strong>
        <br className="d-inline d-sm-none" />
        <span className="d-none d-sm-inline"> </span>
        Upgrade your account to see more.
      </p>
      <div className={styles.ctaContainer}>
        <div className={styles.cta}>
          <img alt="Patreon rewards" src="/images/cta-patreon.png" />
          <Button className={styles.ctaButton} as="a" {...patreonLinkProps}>
            Be an {patronTitleForPledgeCents(SHARING_PLEDGE_CENTS)} ($
            {SHARING_PLEDGE_CENTS / 100})
          </Button>
          <p>Access {totalItemCount} items &amp; more with monthly support.</p>
        </div>

        <div className={styles.cta}>
          <img alt="Access Code rewards" src="/images/cta-access-code.png" />
          <Button className={styles.ctaButton} as="a" {...accessCodeLinkProps}>
            Get Book Access Codes
          </Button>
          <p>Unlock access to every printed magic item forever</p>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(UpgradeCTA)
