export const HERO_TIER_ID = '3107316' // Hero: $3
export const ELITE_TIER_ID = '3102729' // Elite: $6 (was $7)
export const ESTEEMED_TIER_ID = '3158865' // Esteemed: $9
export const LEGENDARY_TIER_ID = '3105154' // Legendary: $12 (was $13, $9)

export const TIER_IDS_TO_CENTS: Record<string, number> = {
  [HERO_TIER_ID]: 300,
  [ELITE_TIER_ID]: 600,
  [ESTEEMED_TIER_ID]: 900,
  [LEGENDARY_TIER_ID]: 1200,
}

/**
 * The minimum amount of cents a user must pledge to be able to view all items
 *
 * **IMPORTANT:** This constant must be kept in sync with the Firestore rules
 */
export const MINIMUM_PLEDGE_CENTS = TIER_IDS_TO_CENTS[ELITE_TIER_ID]

/**
 * The amount of cents a user must pledge to be able to share access to items
 * (limit of 4 users).
 */
export const SHARING_PLEDGE_CENTS = TIER_IDS_TO_CENTS[ESTEEMED_TIER_ID]

/**
 * The amount of cents a user must pledge to be able to share access to items
 * (limit of 6 users).
 */
export const BONUS_SHARING_PLEDGE_CENTS = TIER_IDS_TO_CENTS[LEGENDARY_TIER_ID]

/**
 * The amount of cents a user must pledge to be able to access art and card files
 */
export const ART_CARD_FILES_CENTS = TIER_IDS_TO_CENTS[HERO_TIER_ID]
