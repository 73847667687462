import React, {useState, FormEvent, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import {myFirebase} from '../firebase/firebase'

import Layout from './Layout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiceD20, faTrash} from '@fortawesome/free-solid-svg-icons'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {Alert, Col, Container, Row} from 'react-bootstrap'
import {RootState, UserState} from '../store/types'
import {currentUserCanShareAccess, currentUserMaxShareSlots} from '../selectors'
import {StoreDispatch} from '../store'
import {actions} from '../actions'
import styles from './ShareAccess.module.scss'
import {BONUS_SHARING_PLEDGE_CENTS} from '../sharedConstants'

interface Props {
  updateInvitedUserEmails: (emails: string[]) => void
  user: UserState | null
  canShareAccess: boolean
  maxShareSlots: number
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  canShareAccess: currentUserCanShareAccess(state),
  maxShareSlots: currentUserMaxShareSlots(state),
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  updateInvitedUserEmails: (emails: string[]) =>
    dispatch(actions.setInvitedUserEmails(emails)),
})

const isSameList = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false

  return JSON.stringify(a) === JSON.stringify(b)
}

const concatEmails = (emails: string[]) => {
  if (emails.length === 0) return ''
  if (emails.length === 1) return emails[0]
  if (emails.length === 2) return emails.join(' and ')

  return `${emails.slice(0, -1).join(', ')} and ${emails.slice(-1)}`
}
const getHasOrHave = (emails: string[]) =>
  emails.length === 1 ? 'has' : 'have'

const getSuccessAlertText = ({
  previousEmails,
  emails,
  maxShareSlots,
}: {
  previousEmails: string[]
  emails: string[]
  maxShareSlots: number
}) => {
  const invitedEmails = emails.slice(0, maxShareSlots)
  const previousInvitedEmails = previousEmails.slice(0, maxShareSlots)

  const addedEmails = invitedEmails.filter(
    email => !previousInvitedEmails.includes(email),
  )
  const accessRemovedEmails = previousInvitedEmails.filter(
    email => !invitedEmails.includes(email),
  )

  const previouslyDisabledEmails = previousEmails.slice(maxShareSlots)
  const removedEmails = previouslyDisabledEmails.filter(
    email => !emails.includes(email),
  )

  const messages: string[] = []
  if (addedEmails.length > 0) {
    messages.push(
      `${concatEmails(addedEmails)} now ${getHasOrHave(
        addedEmails,
      )} full access to the Ledger+!`,
    )
  }
  if (accessRemovedEmails.length > 0) {
    messages.push(
      `${concatEmails(accessRemovedEmails)} no longer ${getHasOrHave(
        accessRemovedEmails,
      )} access to the Ledger+.`,
    )
  }
  if (removedEmails.length > 0) {
    messages.push(`${concatEmails(removedEmails)} has been removed.`)
  }

  return messages.join(' ')
}

const getPaddedEmails = (emails: string[]) => {
  return emails.concat(new Array(6 - emails.length).fill(''))
}

const ShareAccess: React.FC<Props> = ({
  user,
  updateInvitedUserEmails,
  canShareAccess,
  maxShareSlots,
}) => {
  const shareAccess = httpsCallable(getFunctions(myFirebase), 'shareAccess')
  const [isSubmitting, setSubmitting] = useState(false)

  const [emails, setEmails] = useState<string[]>([])
  const [alert, setAlert] = useState<null | {
    text: string
    variant: 'success' | 'danger'
  }>(null)

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    if (isSubmitting) return
    setSubmitting(true)

    const formattedEmails = emails.map(email => email.trim()).filter(Boolean)

    try {
      await shareAccess(formattedEmails)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      setSubmitting(false)
      setAlert({text: err.message, variant: 'danger'})
      console.error(err)
      return
    }
    const previousEmails = user?.invitedUserEmails || []

    const alertText = getSuccessAlertText({
      previousEmails,
      emails: formattedEmails,
      maxShareSlots,
    })

    setAlert({text: alertText, variant: 'success'})
    updateInvitedUserEmails(formattedEmails)
    setSubmitting(false)
  }
  const currentEmails = getPaddedEmails(user?.invitedUserEmails || [])

  useEffect(() => {
    if (!isSameList(currentEmails, emails)) {
      setEmails(currentEmails)
    }
  }, [user?.invitedUserEmails])

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null)
      }, 10000)
    }
  }, [alert])

  if (!canShareAccess) {
    return <Redirect to="/" />
  }

  const buttonDisabled = isSubmitting || isSameList(emails, currentEmails)

  return (
    <Layout>
      <Alert
        dismissible
        variant={alert?.variant}
        show={Boolean(alert)}
        transition
      >
        {alert?.text}
      </Alert>
      <form onSubmit={submit}>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>
              Share access with up to {maxShareSlots} friends
              <Modal.Title as="h6" className="text-muted">
                Give someone access to all the items by adding their email here.
                Make sure they have first created a free account!
              </Modal.Title>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{gap: 4, display: 'flex', flexDirection: 'column'}}
            >
              {emails.map((email, idx) => {
                const updateEmail = (updatedEmail: string) =>
                  setEmails(prev =>
                    prev.map((prevEmail, prevEmailIdx) =>
                      prevEmailIdx === idx ? updatedEmail : prevEmail,
                    ),
                  )
                return (
                  <Fragment key={idx}>
                    {idx === maxShareSlots && (
                      <p className={styles.upsellNote}>
                        ${BONUS_SHARING_PLEDGE_CENTS / 100} patrons can share
                        with two more people!
                      </p>
                    )}
                    <Row>
                      <Col style={{display: 'flex', alignItems: 'center'}}>
                        <FormControl
                          disabled={idx + 1 > maxShareSlots}
                          placeholder={`Email ${idx + 1}`}
                          type="text"
                          value={email}
                          onChange={e => updateEmail(e.target.value)}
                        />
                      </Col>
                      <Col xs="auto">
                        <Button
                          disabled={!email}
                          onClick={() => updateEmail('')}
                          variant="outline-primary"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                )
              })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={buttonDisabled}>
              {isSubmitting ? (
                <>
                  <FontAwesomeIcon icon={faDiceD20} spin /> Loading&hellip;
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Layout>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareAccess)
