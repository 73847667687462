import React, {useRef, useEffect, useState} from 'react'
import classnames from 'classnames'

import Modal from 'react-bootstrap/Modal'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import styles from './Dialog.module.scss'

interface DialogProps {
  title: string
  submitTitle: string
  onSubmit: (value?: string) => void
  exit: () => void
  prompt?: boolean
  initialPromptValue?: string
  show: boolean
}

interface DialogButtonProps {
  primary?: boolean
  submit?: boolean
  autoFocus?: boolean
  onClick: () => void
}

const DialogButton: React.FC<DialogButtonProps> = props => {
  const {autoFocus} = props
  const buttonElement = useRef<HTMLButtonElement & Button>(null)

  useEffect(() => {
    if (autoFocus && buttonElement?.current) {
      buttonElement.current.focus()
    }
  }, [autoFocus])

  return (
    <Button
      ref={buttonElement}
      variant="link"
      className={classnames(styles.DialogButton, {
        [styles.DialogButton__primary]: props.primary,
      })}
      onClick={props.onClick}
      type={props.submit ? 'submit' : undefined}
    >
      {props.children}
    </Button>
  )
}

const Dialog: React.FC<DialogProps> = props => {
  const {
    show,
    title,
    submitTitle,
    exit,
    onSubmit,
    prompt,
    initialPromptValue,
  } = props

  const [promptValue, setPromptValue] = useState<string | undefined>(undefined)

  const inputElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (show && inputElement?.current) {
      inputElement.current.focus()
    }
  }, [show])

  return (
    <Modal show={show} onHide={exit} onExited={() => setPromptValue(undefined)}>
      <Modal.Body>
        <div
          className="text-center font-weight-bold"
          style={{marginBottom: '1rem'}}
        >
          {title}
        </div>
        {prompt && (
          <form
            onSubmit={e => {
              onSubmit(promptValue)
              e.preventDefault()
            }}
          >
            <FormControl
              ref={inputElement}
              className={styles.Dialog__prompt_input}
              onChange={event => setPromptValue(event.currentTarget.value)}
              defaultValue={initialPromptValue}
            />
          </form>
        )}
      </Modal.Body>
      <ButtonGroup>
        <DialogButton onClick={exit}>Cancel</DialogButton>
        <DialogButton
          submit
          autoFocus={!prompt}
          onClick={() => onSubmit(promptValue)}
          primary
        >
          {submitTitle}
        </DialogButton>
      </ButtonGroup>
    </Modal>
  )
}

export default Dialog
