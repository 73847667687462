import React from 'react'
import classnames from 'classnames'

import {SelectComponents} from 'react-select/src/components'

import {FiltersOptionType} from './types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faSearch,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import styles from './DropdownIndicator.module.scss'

export const DropdownIndicator: SelectComponents<
  FiltersOptionType
>['DropdownIndicator'] = props => {
  const {menuIsOpen} = props.selectProps
  return (
    <FontAwesomeIcon
      size="3x"
      icon={menuIsOpen ? faChevronRight : faSearch}
      mask={faCircle}
      transform="shrink-8"
      className={classnames(
        styles.filterSearchButton,
        'text-primary',
        'd-lg-none', // hide on large screens
      )}
      style={{
        alignSelf: 'flex-end',
        cursor: 'pointer',
        ...(menuIsOpen ? {opacity: 1} : {}),
      }}
    />
  )
}
