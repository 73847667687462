import React from 'react'

import {DateColumnMode} from '../store/types'
import styles from './DateColumnModeButton.module.scss'
import SvgIcon from './SvgIcon'

interface Props {
  mode: DateColumnMode
  onClick: (nextMode: DateColumnMode) => void
}

export const DateColumnModeButton: React.FC<Props> = ({mode, onClick}) => {
  const nextMode = mode === 'book' ? 'date' : mode === 'date' ? 'card' : 'book'
  return (
    <button
      className={styles.DateColumnModeButton}
      onClick={e => {
        e.stopPropagation()
        onClick(nextMode)
      }}
      title={
        nextMode === 'book'
          ? 'Show book'
          : nextMode === 'card'
          ? 'Show card volume'
          : nextMode === 'date'
          ? 'Show date'
          : ''
      }
      style={{lineHeight: '18px'}}
    >
      <SvgIcon
        fixedWidth
        size={18}
        iconName={
          mode === 'book' ? 'book' : mode === 'card' ? 'card-pack' : 'date'
        }
      />
    </button>
  )
}
