import React from 'react'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

import {ItemBundle} from '../../store/types'

import {StashMenuProps} from './StashMenuTooltip'
import StashThumbnail from './StashThumbnail'
import StashThumbnailContainer from './StashThumbnailContainer'
import Square from '../Square'

import styles from './StashPreview.module.scss'

interface StashPreviewProps extends StashMenuProps {
  editable?: boolean
  stash: ItemBundle
  onClick: (stash: ItemBundle) => void
}

export const StashPreview: React.FC<StashPreviewProps> = props => {
  const {stash, editable, onClick, children, ...menuProps} = props

  return (
    <div className={styles.StashPreview} onClick={() => onClick(stash)}>
      <StashThumbnail editable={editable} stash={stash} {...menuProps} />

      <div className={styles.StashPreview__title}>
        <span>{stash.name}</span>
      </div>
    </div>
  )
}

interface NewStashButtonProps {
  onClick: () => void
}

export const NewStashButton: React.FC<NewStashButtonProps> = props => {
  const {onClick} = props
  return (
    <div className={styles.StashPreview} onClick={() => onClick()}>
      <StashThumbnailContainer>
        <Square className={styles.StashThumbnail__emptycontainer}>
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </Square>
      </StashThumbnailContainer>

      <div
        className={classnames(
          styles.StashPreview__title,
          styles.StashPreview__title_new_stash,
        )}
      >
        <span>New Stash</span>
      </div>
    </div>
  )
}

export const PlaceholderStash: React.FC = () => {
  return (
    <div className={styles.StashPreview}>
      <StashThumbnailContainer highlighted>
        <Square className={styles.StashThumbnail__emptycontainer} />
      </StashThumbnailContainer>
    </div>
  )
}
