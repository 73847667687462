import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {useDispatch} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFile,
  faCheck,
  faFileImage,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

import {StoreDispatch} from '../../store'
import {uploadImage} from '../../actions/thunks'
import ImageDisplay from '../ImageDisplay'
import styles from './ImageUpload.module.scss'

interface Props {
  imageId?: string
  existingId?: string
  onUpload: (_: {imageId: string}) => void
}

const SquareDiv: React.FunctionComponent<{isPlaceholder?: boolean}> = ({
  children,
  isPlaceholder,
}) => {
  return (
    <div
      className={classnames(
        styles.squarediv,
        isPlaceholder && styles['squarediv_content-placeholder'],
      )}
    >
      <div className={styles.squarediv_content}>{children}</div>
    </div>
  )
}

const UploadPlaceholder: React.FunctionComponent<{
  uploading?: boolean
  isDragActive?: boolean
}> = ({uploading, isDragActive}) => (
  <SquareDiv isPlaceholder>
    {uploading ? (
      <>
        <FontAwesomeIcon
          size="5x"
          color="#ddd"
          icon={faFileUpload}
          spin
          className={styles.bounce}
        />
      </>
    ) : isDragActive ? (
      <>
        <FontAwesomeIcon
          size="5x"
          color="#ddd"
          mask={faFile}
          icon={faCheck}
          transform="shrink-8 down-2"
          className={styles.shake}
        />
      </>
    ) : (
      <>
        <FontAwesomeIcon size="5x" color="#ddd" icon={faFileImage} />
      </>
    )}
  </SquareDiv>
)

export const ImageUpload: React.FunctionComponent<Props> = ({
  imageId,
  existingId,
  onUpload,
}) => {
  const dispatch = useDispatch<StoreDispatch>()
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true)

      const uploadedFileId = await dispatch(uploadImage(acceptedFiles[0]))

      onUpload({imageId: uploadedFileId})
      setUploading(false)
    },
    [onUpload, dispatch],
  )
  const [uploading, setUploading] = useState<boolean>()

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    disabled: uploading,
    accept: 'image/png',
    multiple: false,
  })

  return (
    <div
      className={classnames('form-group', styles.ImageUpload, {
        [styles['ImageUpload-busy']]: uploading,
      })}
      {...getRootProps()}
    >
      {imageId && !isDragActive && !uploading ? (
        <SquareDiv>
          <ImageDisplay
            key={imageId}
            imageId={imageId}
            size={existingId === imageId ? 'medium' : 'original'}
            fullWidth
          />
        </SquareDiv>
      ) : (
        <UploadPlaceholder {...{uploading, isDragActive}} />
      )}

      <input {...getInputProps()} />
    </div>
  )
}

export default ImageUpload
