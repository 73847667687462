import React from 'react'
import Button from 'react-bootstrap/Button'

import {accessCodeUrl, patreonUrl} from './externalLinks'

import styles from './Footer.module.scss'

type FooterProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className'
>

const Footer: React.FC<FooterProps> = ({children, ...props}) => (
  <div className={styles.Footer} {...props}>
    <div className={styles.Footer_ctaContainer}>
      <Button as="a" variant="outline-light" href={patreonUrl} target="_blank">
        Become a Patron
      </Button>
      <Button
        as="a"
        variant="outline-light"
        href={accessCodeUrl}
        target="_blank"
      >
        Buy Access Code
      </Button>
    </div>
    <span className={styles.Footer_copyright}>
      Copyright &copy; {new Date().getFullYear()} The Griffon&apos;s Saddlebag.
      All rights reserved.
    </span>
  </div>
)

export default Footer
