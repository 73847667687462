import React from 'react'
import classnames from 'classnames'

import {components as selectComponents} from 'react-select'
import {SelectComponents} from 'react-select/src/components'

import {FiltersOptionType} from './types'

export const SelectContainer: SelectComponents<
  FiltersOptionType
>['SelectContainer'] = ({className, ...props}) => {
  className = classnames(className, {
    'ledger-filters-menu-showing': props.selectProps.menuIsOpen,
  })
  return <selectComponents.SelectContainer {...props} className={className} />
}
