import {sortableItemDate} from '../utils'
import {GriffonDatabaseItem, GriffonItem} from './types'

const cardVolumeForDate = (isoDate: string) => {
  if (isoDate < '2018-12-27') return 1
  else if (isoDate < '2019-02-15') return 2
  else if (isoDate < '2019-04-03') return 3
  else if (isoDate < '2019-05-26') return 4
  else if (isoDate < '2019-07-15') return 5
  else if (isoDate < '2019-09-03') return 6
  else if (isoDate < '2019-11-07') return 7

  return null
}

const bookVolumeForDate = (isoDate: string) => {
  if (isoDate < '2019-11-07') return 1
  if (isoDate < '2021-11-01') return 2
  if (isoDate < '2023-11-02') return 3

  return null
}

export const setDerivedPropertiesForItem = (
  item: GriffonDatabaseItem,
): GriffonItem => {
  const itemDate = sortableItemDate(item)
  return {
    ...item,
    bookVolume: bookVolumeForDate(itemDate),
    cardVolume: cardVolumeForDate(itemDate),
  }
}
