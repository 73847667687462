import React, {useEffect, useState, useRef, useMemo} from 'react'
import {connect} from 'react-redux'
import {RouteComponentProps, withRouter, Link} from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons'

import {
  RootState,
  ItemBundle,
  GriffonItem,
  ListIdentifier,
} from '../../store/types'
import {bundlesById} from '../../selectors'
import {actions, thunks} from '../../actions'

import LimitedMaxWidthContainer from '../LimitedMaxWidthContainer'
import Layout from '../Layout'
import ItemList from '../ItemList'
import ScrollToTopOnMount from '../ScrollToTopOnMount'
import {StoreDispatch} from '../../store'

import StashMenuTooltip from './StashMenuTooltip'
import useStashManagement from './useStashManagement'

import styles from './StashView.module.scss'
import Filters from '../Filters'

interface RouteParams {
  stashId: string
}

interface StashViewStateProps {
  stash: ItemBundle
}

interface StashViewDispatchProps {
  setStash: (stashId: string) => void
  updateStashItems: (stash: ItemBundle, items: string[]) => void
  dispatch: StoreDispatch
}

const mapDispatchToProps = (
  dispatch: StoreDispatch,
): StashViewDispatchProps => ({
  setStash: (stashId: string) => dispatch(actions.setSelectedBundle(stashId)),
  updateStashItems: (stash: ItemBundle, items: string[]) =>
    dispatch(thunks.updateStashItems(stash, items)),
  dispatch,
})

const mapStateToProps = (
  state: RootState,
  ownProps: RouteComponentProps<RouteParams>,
): StashViewStateProps => ({
  stash: bundlesById(state)[ownProps.match.params.stashId],
})

type StashViewProps = StashViewStateProps &
  StashViewDispatchProps &
  RouteComponentProps<RouteParams>

const StashView: React.FC<StashViewProps> = props => {
  const {match, setStash, stash, updateStashItems, history, dispatch} = props

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])

  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)

  const [showingMenu, setShowingMenu] = useState(false)
  const menuTarget = useRef<HTMLButtonElement>(null)

  const beginAdding = () => {
    setSelectedItemIds(stash.items)
    setAdding(true)
  }

  const beginRemoving = () => {
    setSelectedItemIds([])
    setRemoving(true)
  }

  const endSelecting = () => {
    setAdding(false)
    setRemoving(false)
    setSelectedItemIds([])
  }

  const navigateToItem = useMemo(
    () => (item: GriffonItem | string) =>
      history.push(
        `/stashes/${stash.id}/items/${
          typeof item === 'string' ? item : item.id
        }`,
      ),
    [history, stash],
  )

  const handleItemSelection = (item: GriffonItem) => {
    if (adding || removing) {
      if (selectedItemIds.includes(item.id))
        setSelectedItemIds(selectedItemIds.filter(id => id !== item.id))
      else setSelectedItemIds([...selectedItemIds, item.id])
    } else {
      navigateToItem(item)
    }
  }

  const {
    render: renderManagementDialogs,
    deleteStash,
    duplicateStash,
    renameStash,
  } = useStashManagement(dispatch, {
    onStashDelete: () => history.push('/stashes'),
    onStashDuplicate: stash => history.push(`/stashes/${stash.id}`),
  })

  useEffect(() => {
    if (match.params.stashId) setStash(match.params.stashId)
  }, [match.params.stashId, setStash])

  const currentListId: ListIdentifier = adding ? 'stashAdd' : 'stash'

  return stash ? (
    <Layout fullWidth>
      <LimitedMaxWidthContainer>
        <ScrollToTopOnMount />
        {renderManagementDialogs()}
        {menuTarget?.current && (
          <StashMenuTooltip
            show={showingMenu}
            onHide={() => setShowingMenu(false)}
            target={menuTarget.current}
            placement="bottom"
            onClickDelete={() => deleteStash(stash)}
            onClickDuplicate={() => duplicateStash(stash)}
            onClickRename={() => renameStash(stash)}
          />
        )}
        <div className={styles.StashView__header}>
          <div>
            <strong>{stash.name}</strong> &nbsp;&nbsp;
            <Button
              variant="link"
              onClick={() => setShowingMenu(true)}
              ref={menuTarget}
            >
              {' '}
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
          </div>
          <div className={styles.StashView__buttons}>
            {adding ? (
              <>
                <Button variant="link" onClick={endSelecting}>
                  Cancel
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={async () => {
                    await updateStashItems(stash, selectedItemIds)
                    endSelecting()
                  }}
                >
                  Stash Selected
                </Button>
              </>
            ) : removing ? (
              <>
                <Button variant="link" onClick={endSelecting}>
                  Cancel
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={async () => {
                    await updateStashItems(
                      stash,
                      stash.items.filter(
                        item => !selectedItemIds.includes(item),
                      ),
                    )
                    endSelecting()
                  }}
                >
                  Remove Selected
                </Button>
              </>
            ) : (
              <>
                <Link to="/stashes">
                  <Button variant="link">Back</Button>
                </Link>
                <Button
                  key="add"
                  variant="outline-primary"
                  onClick={beginAdding}
                >
                  Add Items
                </Button>
                <Button
                  key="remove"
                  variant="outline-primary"
                  onClick={beginRemoving}
                >
                  Remove Items
                </Button>
              </>
            )}
          </div>
        </div>
        <Filters
          listId={currentListId}
          navigateToItem={adding || removing ? null : navigateToItem}
        />
        <ItemList
          listId={currentListId}
          selectionStyle={adding ? 'select' : removing ? 'remove' : undefined}
          selectedItemIds={selectedItemIds}
          onItemClick={handleItemSelection}
        />
      </LimitedMaxWidthContainer>
    </Layout>
  ) : (
    <Layout error="Stash not found" />
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StashView),
)
