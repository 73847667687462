import React, {FormEvent} from 'react'
import Layout from '../Layout'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import {myFirebase} from '../../firebase/firebase'
import {getFunctions, httpsCallable} from 'firebase/functions'

interface State {
  loading: boolean
  type?: string
  count?: number
  note?: string
}

type Props = Record<string, never>

class GenerateCodes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {loading: false}
  }

  numberToGenerate(): number {
    return Math.max(1, this.state.count || 1)
  }

  valid(): boolean {
    return this.numberToGenerate() > 0 && Boolean(this.state.type)
  }

  async submit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    this.setState({loading: true})

    const generateAccessCodes = httpsCallable(
      getFunctions(myFirebase),
      'generateAccessCodes',
    )

    const results = (
      await generateAccessCodes({
        count: this.state.count,
        note: this.state.note,
        type: this.state.type,
      })
    ).data as {
      batch: {id: string}
      codes: Array<{id: string}>
    }

    const element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(
          results.codes.map((c: {id: string}) => c.id).join('\n'),
        ),
    )
    element.setAttribute(
      'download',
      `LedgerPlus-AccessCodes-${results.batch.id}.txt`,
    )

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)

    this.setState({
      loading: false,
      count: undefined,
      note: undefined,
      type: undefined,
    })
  }

  render(): JSX.Element {
    return (
      <Layout>
        <h4>Generate Access Code(s)</h4>

        <Row>
          <Col md={6}>
            <form onSubmit={this.submit.bind(this)}>
              <Form.Group controlId="accessCodeType">
                <Form.Label>Type of access code</Form.Label>
                <Form.Control
                  as="select"
                  onChange={event =>
                    this.setState({type: event.currentTarget.value})
                  }
                  value={this.state.type || ''}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  <option value="book_1">Book 1</option>
                  <option value="book_2">Book 2</option>
                  <option value="book_3">Book 3</option>
                  <option value="total_access">Total Access</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="accessCodeCount">
                <Form.Label>Number of codes to generate</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  placeholder="1"
                  min="1"
                  value={(this.state.count || '').toString()}
                  onChange={event =>
                    this.setState({count: parseInt(event.currentTarget.value)})
                  }
                />
              </Form.Group>

              <Form.Group controlId="accessCodeNote">
                <Form.Label>Code/batch description</Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  value={this.state.note || ''}
                  onChange={event =>
                    this.setState({note: event.currentTarget.value})
                  }
                />
                <Form.Text muted>
                  For your own reference. Not shown to users.
                </Form.Text>
              </Form.Group>

              <Button
                disabled={this.state.loading || !this.valid()}
                type="submit"
              >
                Generate {this.numberToGenerate()} code
                {this.numberToGenerate() !== 1 ? 's' : ''}
              </Button>
            </form>
          </Col>
          <Col md={6}></Col>
        </Row>
      </Layout>
    )
  }
}

export default GenerateCodes
