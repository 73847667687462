import React from 'react'
import {Async} from 'react-async'
import {Link} from 'react-router-dom'
import Table from 'react-bootstrap/Table'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'

import {AccessCodeBatch, WithId} from '../../store/types'
import {db} from '../../firebase/firebase'
import Layout from '../Layout'
import {collection, getDocs, orderBy, query} from 'firebase/firestore'

const loadBatches = async (): Promise<Array<AccessCodeBatch & WithId>> => {
  return (
    await getDocs(
      query(
        collection(db, 'accessCodeBatches'),
        orderBy('generatedAt', 'desc'),
      ),
    )
  ).docs.map(d => ({...(d.data() as AccessCodeBatch), id: d.id}))
}

const formatBatchCodeType = (type: AccessCodeBatch['type']): string => {
  return {
    book_1: 'Book 1',
    book_2: 'Book 2',
    total_access: 'Total Access',
  }[type]
}

const currentCodeTypes: Array<AccessCodeBatch['type']> = [
  'total_access',
  'book_1',
]

const ListCodeBatches: React.FC = () => {
  return (
    <Layout>
      <h4>
        Access Code Batches{' '}
        <Link to="/codes/generate" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> Generate Codes
        </Link>
      </h4>

      <Async promiseFn={loadBatches}>
        {({data, error, isPending}) => {
          if (isPending) return 'Loading...'
          if (error) return `Something went wrong: ${error.message}`
          if (data)
            return (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Batch ID</th>
                    <th>Generated at</th>
                    <th>Code type</th>
                    <th>Batch size</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(batch => (
                    <tr key={batch.id}>
                      <td>
                        <Link to={`/codes/${batch.id}`}>
                          <code>{batch.id}</code>
                        </Link>
                      </td>
                      <td>{batch.generatedAt.toDate().toLocaleString()}</td>
                      <td>{formatBatchCodeType(batch.type)}</td>
                      <td>{batch.size}</td>
                      <td>{batch.note}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  {currentCodeTypes.map(type => (
                    <tr key={type}>
                      <th></th>
                      <th></th>
                      <th>{formatBatchCodeType(type)}</th>
                      <th>
                        <strong>
                          {data
                            .filter(b => b.type === type)
                            .map(b => b.size)
                            .reduce((v, r) => v + r, 0)}
                        </strong>
                      </th>
                      <th></th>
                    </tr>
                  ))}
                </tfoot>
              </Table>
            )
          return null
        }}
      </Async>
    </Layout>
  )
}

export default ListCodeBatches
