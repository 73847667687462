import React from 'react'
import LazyLoad from 'react-lazyload'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import * as types from '../store/types'

import Square from './Square'
import ImageDisplay from './ImageDisplay'

export const attunementOverlay = (item: types.GriffonItem): JSX.Element => {
  const content = item.attunement
    ? item.attunement === true
      ? 'Yes'
      : 'Yes +'
    : ''
  return (
    <>
      {typeof item.attunement === 'string' && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={'attunement-tooltip-' + item.id}>
              {item.attunement}
            </Tooltip>
          }
        >
          <div
            style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}
          />
        </OverlayTrigger>
      )}
      {content}
    </>
  )
}

export const thumbnailOverlay = (imageId: string): JSX.Element => {
  return (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip
            id={`thumbnail-tooltip-${imageId}`}
            className="tooltip-white readonly"
          >
            <ImageDisplay
              thumbnail={false}
              imageId={imageId}
              size="medium"
              dimension={250}
            />
          </Tooltip>
        }
      >
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        />
      </OverlayTrigger>
      <div style={{width: 33}}>
        <Square>
          <LazyLoad offset={1000}>
            <ImageDisplay
              thumbnail={false}
              imageId={imageId}
              size="tiny"
              dimension={33}
            />
          </LazyLoad>
        </Square>
      </div>
    </>
  )
}
