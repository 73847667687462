import {connect} from 'react-redux'
import React, {useEffect, useRef} from 'react'
import classnames from 'classnames'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import {forceCheck} from 'react-lazyload'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faSortDown,
  faSortUp,
  faSort,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'

import {StoreDispatch} from '../store'
import * as types from '../store/types'
import * as selectors from '../selectors'
import {actions} from '../actions'

import ItemListRow from './ItemListRow'

import styles from './ItemList.module.scss'
import {useScrollPosition} from '@n8tb1t/use-scroll-position'
import {dateModeLabel} from '../utils'
import DateModeLabel from './DateModeLabel'
import {SortingHat} from './LootTable/SortingHat'
import {
  downloadXlsxForMultipleTables,
  downloadXlsxForTable,
} from './LootTable/generateExcel'
import SvgIcon from './SvgIcon'

type SelectionStyle = 'select' | 'remove'

interface OwnProps {
  listId: types.ListIdentifier
  selectionStyle?: SelectionStyle
  selectedItemIds?: string[]
  onItemClick: (item: types.GriffonItem) => void
}

const mapStateToProps = (state: types.RootState, ownProps: OwnProps) => ({
  isAdmin: state.auth.user?.isAdmin || false,
  visibleItems: selectors.getSortedVisibleItems(state, ownProps.listId),
  sortColumn: state.listViews[ownProps.listId].sortColumn,
  sortDirection: state.listViews[ownProps.listId].sortDirection,
  visibleSecondaryColumn:
    state.listViews[ownProps.listId].visibleSecondaryColumn,
  dateColumnMode: state.listViews[ownProps.listId].dateColumnMode,
  freeColumnVisible: state.ui.freeColumnVisible,
  sortingHat: selectors.getSortingHatForVisibleItems(state, ownProps.listId),
  entireLedgerSortingHats: selectors.getSortingHatsForEntireLedger(
    state,
    ownProps.listId,
  ),
  lootTableFilename: selectors.getLootTableFilename(state, ownProps.listId),
})

const mapDispatchToProps = (dispatch: StoreDispatch, ownProps: OwnProps) => ({
  setSelectedItem: (item: types.GriffonItem) =>
    dispatch(actions.setSelectedItem(item.id, ownProps.listId)),
  toggleSortColumn: (column: types.SortColumn) =>
    dispatch(actions.uiToggleSortColumn(column, ownProps.listId)),
  setVisibleSecondaryColumn: (column: Exclude<types.SortColumn, 'name'>) =>
    dispatch(actions.uiSetVisibleSecondaryColumn(column, ownProps.listId)),
  setNavExtraVerticalSpace: (space: number) =>
    dispatch(actions.uiSetNavExtraVerticalSpace(space)),
  setDateColumnMode: (mode: types.DateColumnMode) =>
    dispatch(actions.uiSetDateColumnMode(mode, ownProps.listId)),
})

interface Props {
  isAdmin: boolean
  adminCanEditItems?: boolean
  visibleItems: types.GriffonItem[]
  setSelectedItem: (item: types.GriffonItem) => void
  toggleSortColumn: (column: types.SortColumn) => void
  sortColumn: types.SortColumn
  sortDirection: types.SortDirection
  visibleSecondaryColumn: Exclude<types.SortColumn, 'name'>
  setVisibleSecondaryColumn: (column: Exclude<types.SortColumn, 'name'>) => void
  setNavExtraVerticalSpace: (space: number) => void
  freeColumnVisible: boolean
  dateColumnMode: types.DateColumnMode
  setDateColumnMode: (currentMode: types.DateColumnMode) => void
  sortingHat: SortingHat
  entireLedgerSortingHats: Array<{sortingHat: SortingHat; title: string}>
  lootTableFilename: string | undefined
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CustomToggle = React.forwardRef(({onClick}, ref) => (
  <div
    href="#"
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref={ref}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
      onClick(e)
    }}
    className={styles.optionButton}
  >
    <FontAwesomeIcon icon={faEllipsisH} />
  </div>
))

CustomToggle.displayName = 'CustomToggle'

const DownloadLootTableIcon: React.FC = () => {
  return <SvgIcon iconName="download-loot-table-2a" />
}

const ItemList: React.FC<Props & OwnProps> = ({
  visibleItems,
  toggleSortColumn,
  sortColumn,
  sortDirection,
  visibleSecondaryColumn,
  setVisibleSecondaryColumn,
  isAdmin,
  adminCanEditItems,
  selectionStyle,
  selectedItemIds,
  onItemClick,
  setNavExtraVerticalSpace,
  freeColumnVisible,
  dateColumnMode,
  setDateColumnMode,
  sortingHat,
  entireLedgerSortingHats,
  lootTableFilename,
}) => {
  const ItemListHeader: React.FC<{
    column?: types.SortColumn
    narrow?: boolean
    secondary?: boolean
    center?: boolean
    sortable?: boolean
  }> = ({children, narrow, column, sortable, center, secondary}) => {
    return (
      <th
        style={narrow ? {width: '1%'} : {}}
        className={classnames({
          [styles.dataColumn]: secondary,
          [styles.columnVisible]:
            secondary && visibleSecondaryColumn === column,
          'text-center': center,
        })}
        onClick={
          column && sortable ? () => toggleSortColumn(column) : undefined
        }
      >
        {children} {column && sortable && <SortIndicator column={column} />}
        {secondary && (
          <>
            <div className={styles.optionButtonSpacer} />
            <Dropdown className={styles.optionButtonDropdownContainer}>
              <Dropdown.Toggle
                id={`secondary-column-toggle-${column}`}
                as={CustomToggle}
              />

              <Dropdown.Menu alignRight>
                <Dropdown.Item
                  disabled={visibleSecondaryColumn === 'type'}
                  onClick={() => setVisibleSecondaryColumn('type')}
                >
                  Type
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={visibleSecondaryColumn === 'rarity'}
                  onClick={() => setVisibleSecondaryColumn('rarity')}
                >
                  Rarity
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={visibleSecondaryColumn === 'attunement'}
                  onClick={() => setVisibleSecondaryColumn('attunement')}
                >
                  Attunement
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={visibleSecondaryColumn === 'significance'}
                  onClick={() => setVisibleSecondaryColumn('significance')}
                >
                  Sub
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={visibleSecondaryColumn === 'date'}
                  onClick={() => setVisibleSecondaryColumn('date')}
                >
                  {dateModeLabel(dateColumnMode)}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={downloadLootTable}>
                  Download loot table <DownloadLootTableIcon />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </th>
    )
  }

  const SortIndicator: React.FunctionComponent<{
    column: types.SortColumn
  }> = ({column}) => {
    if (column !== sortColumn)
      return (
        <FontAwesomeIcon
          icon={faSort}
          className={styles.sortIndicatorInactive}
        />
      )

    return (
      <FontAwesomeIcon
        icon={sortDirection === types.SORT_ASC ? faSortDown : faSortUp}
      />
    )
  }

  const headerRowRef = useRef<HTMLTableRowElement>(null)
  const headerCellRef = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    forceCheck()
  }, [visibleItems])

  useScrollPosition(() => {
    if (!headerRowRef.current || !headerCellRef.current) return

    const rowRect = headerRowRef.current.getBoundingClientRect()
    const cellRect = headerCellRef.current.getBoundingClientRect()

    const verticalSpaceNeeded = rowRect.height

    setNavExtraVerticalSpace(
      rowRect.top !== cellRect.top ? verticalSpaceNeeded : 0,
    )
  })

  const showEditColumn = Boolean(adminCanEditItems && isAdmin)
  const showSelectColumn = selectionStyle !== undefined

  const downloadLootTable = () => {
    try {
      if (entireLedgerSortingHats.length) {
        entireLedgerSortingHats.forEach(({sortingHat, title}) => {
          console.debug(`Loot Table ${title}`)
          console.debug(sortingHat.output())
        })
        downloadXlsxForMultipleTables(
          entireLedgerSortingHats.map(({sortingHat, title}) => ({
            table: sortingHat.distribute(),
            title,
          })),
        )
      } else {
        console.debug(sortingHat.output())
        downloadXlsxForTable(sortingHat.distribute(), lootTableFilename)
      }
    } catch (e) {
      console.error('Unable to download loot table', e)
      window.alert(
        'Sorry, it looks like there are too many items here to generate a loot table! ' +
          'Try narrowing down the list using more specific filters, or clear all filters ' +
          'to download loot tables A through I.',
      )
    }
  }

  return (
    <Table hover striped className={styles.itemtable}>
      <thead>
        <tr className={styles.listheaderrow} ref={headerRowRef}>
          <td className="edge" ref={headerCellRef} />
          {showSelectColumn && <ItemListHeader />}
          {showEditColumn && <th className="d-none d-md-table-cell" />}
          {/* below is the meta thumbnail */}
          <th
            style={{width: '1%', padding: 0, textAlign: 'center'}}
            className="d-none d-md-table-cell"
          >
            {!showSelectColumn && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="download-loot-table-tooltip">
                    Download loot table
                  </Tooltip>
                }
              >
                <Button variant="link" size="sm" onClick={downloadLootTable}>
                  <DownloadLootTableIcon />
                </Button>
              </OverlayTrigger>
            )}
          </th>
          <ItemListHeader narrow />
          <ItemListHeader column="name" sortable>
            Name
          </ItemListHeader>
          <ItemListHeader column="type" sortable secondary>
            Type
          </ItemListHeader>
          <ItemListHeader column="rarity" sortable secondary center>
            Rarity
          </ItemListHeader>
          <ItemListHeader column="attunement" sortable secondary center>
            Attune<span className="d-none d-md-inline">ment</span>
          </ItemListHeader>
          <ItemListHeader
            column="significance"
            sortable
            secondary
            center
            narrow
          >
            Sub
          </ItemListHeader>
          <ItemListHeader column="date" sortable secondary center>
            <DateModeLabel
              dateColumnMode={dateColumnMode}
              setDateColumnMode={setDateColumnMode}
            />
          </ItemListHeader>
          {freeColumnVisible && (
            <ItemListHeader column="free" sortable secondary narrow>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="free-ogl-tooltip">
                    All items in the Free tag are considered OGL content. Credit
                    must be given to The Griffon’s Saddlebag when used, and any
                    item’s art or description cannot be altered when you do.
                  </Tooltip>
                }
              >
                <span>Free</span>
              </OverlayTrigger>
            </ItemListHeader>
          )}
          <td className="edge" />
        </tr>
      </thead>
      <tbody>
        {visibleItems.map(item => (
          <ItemListRow
            freeColumnVisible={freeColumnVisible}
            showEditColumn={showEditColumn}
            showSelectColumn={showSelectColumn}
            selectionStyle={selectionStyle}
            visibleSecondaryColumn={visibleSecondaryColumn}
            dateColumnMode={dateColumnMode}
            key={item.id}
            item={item}
            selected={Boolean(selectedItemIds?.includes(item.id))}
            onClick={onItemClick}
          />
        ))}
      </tbody>
    </Table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemList)
