import React, {useEffect} from 'react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import {Link} from 'react-router-dom'
import LazyLoad, {forceCheck} from 'react-lazyload'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'

import ImageDisplay from './ImageDisplay'
import * as types from '../store/types'
import Square from './Square'

interface Props {
  items: types.GriffonItem[]
  setSelectedItem: (item: types.GriffonItem) => void
  isAdmin: boolean
}

const styleKey: 'compact' | 'normal' = 'compact'
const colStyle = styleKey === 'compact' ? {lg: 3, md: 4, sm: 6} : {lg: 4, sm: 6}

const ItemRarityBadge: React.FC<{item: types.GriffonItem}> = ({item}) => {
  let text = '-'
  let color = '#ddd'
  switch (item.rarity) {
    case 'rare':
      text = 'R'
      color = '#9CBDFF'
      break
    case 'very rare':
      text = 'VR'
      color = '#6FDFB4'
      break
    case 'legendary':
      text = 'L'
      color = '#fc0'
      break
    case 'uncommon':
      text = 'UC'
      color = '#b87bed'
      break
    case 'common':
      text = 'C'
      color = '#E77777'
      break
    case 'artifact':
      text = 'A'
      color = '#f82'
  }

  const style: {backgroundColor?: string} = {}
  if (color) style.backgroundColor = color

  return (
    <Badge pill style={style}>
      {text}
    </Badge>
  )
}

const ItemGrid: React.FunctionComponent<Props> = ({
  items,
  setSelectedItem,
  isAdmin,
}) => {
  useEffect(() => {
    forceCheck()
  }, [items])

  return (
    <React.Fragment>
      <Row>
        {items.map(item => (
          <Col key={item.id} {...colStyle}>
            <div
              style={{width: '100%', cursor: 'pointer'}}
              onClick={() => setSelectedItem(item)}
            >
              {item.imageIds?.[0] && (
                <Square>
                  <LazyLoad offset={400}>
                    <ImageDisplay
                      fullWidth
                      imageId={item.imageIds?.[0]}
                      size="small"
                    />
                  </LazyLoad>
                </Square>
              )}
            </div>
            <p>
              {isAdmin && (
                <>
                  <Link to={`/items/${item.id}/edit`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>{' '}
                </>
              )}
              <ItemRarityBadge item={item} /> <strong>{item.name}</strong>
            </p>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default ItemGrid
