import React from 'react'

import {GriffonItem} from '../store/types'
import {rarityLetterForItem} from '../utils'

interface Props {
  item: GriffonItem
  width: number | string
}

const typeColourForItem = (item: GriffonItem) => {
  switch (item.itemType.toLowerCase()) {
    case 'weapon':
      return '#C07A65'
    case 'armor':
      return '#CA9F7C'
    case 'wondrous item':
      return '#DBC586'
    case 'ring':
      return '#B1D196'
    case 'rod':
      return '#7DB39F'
    case 'wand':
      return '#AED4D4'
    case 'staff':
      return '#738FAE'
    case 'potion':
      return '#A289C0'
    case 'scroll':
      return '#C386B5'
  }
  return '#ddd'
}

const textForItem = (item: GriffonItem) => {
  const attunementSuffix = item.attunement ? '*' : ''
  return rarityLetterForItem(item) + attunementSuffix
}

const ItemMetaThumbnail: React.FC<Props> = ({item, width}) => (
  <div
    style={{
      textAlign: 'center',
      color: 'white',
      fontWeight: 'bold',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: width,
      backgroundColor: typeColourForItem(item),
      WebkitTextStroke: '1px black',
    }}
  >
    {textForItem(item)}
  </div>
)

export default ItemMetaThumbnail
