export const patreonUrl = 'https://www.patreon.com/the_griffons_saddlebag'
export const accessCodeUrl =
  'https://thedeckofmany.com/products/the-griffons-saddlebag-the-ledger-book-one-access-code'

export const externalLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

export const patreonLinkProps = {
  ...externalLinkProps,
  href: patreonUrl,
}

export const accessCodeLinkProps = {
  ...externalLinkProps,
  href: accessCodeUrl,
}
