import {createSelector} from 'reselect'
import {
  RootState,
  SaddlebagFilterOptions,
  GriffonItem,
  Rarity,
  SortColumn,
  ListIdentifier,
  ISearchTag,
} from '../store/types'
import {sortableItemDate} from '../utils'
import {getFilters, getSortColumn, getSortDirection} from './lists'
import {getSelectedBundle, getSelectedBundleItems} from './bundles'
import {setDerivedPropertiesForItem} from '../store/items'
import {
  SingleRarityHat,
  SortingHat,
  WeightedHat,
} from '../components/LootTable/SortingHat'
import {SortingHatItem, Subrarity} from '../components/LootTable/items'

const getDatabaseContents = (state: RootState) => state.contents
const getContents = createSelector([getDatabaseContents], databaseContents =>
  databaseContents?.map(setDerivedPropertiesForItem),
)

const getSelectedItemId = (state: RootState) => state.selectedItemId

const getListId = (_: RootState, listId: ListIdentifier) => listId
export const getListContents = createSelector(
  [getListId, getContents, getSelectedBundleItems],
  (listId, allContents, bundleContents) => {
    if (listId === 'stash') {
      return bundleContents
    } else {
      return allContents
    }
  },
)

export const getAllSubtypes = createSelector([getContents], contents => {
  if (!contents) {
    return []
  }

  const subtypeSet = new Set(
    contents.map(item => item.itemSubtype).filter((s): s is string => !!s),
  )
  return Array.from(subtypeSet).sort()
})

export const getFilterOptions = createSelector(
  [getListContents],
  (contents): SaddlebagFilterOptions => {
    const emptyState = {
      attunementType: [],
      itemSubtype: {},
      itemType: [],
      isFree: [],
    }
    if (!contents) {
      return emptyState
    }

    const attunementTypeSet = new Set<string>()
    const itemTypeSet = new Set<string>()
    const itemSubtypeMap: {[itemType: string]: Set<string>} = {}

    for (const item of contents) {
      if (typeof item.attunement === 'string')
        attunementTypeSet.add(item.attunement)

      itemTypeSet.add(item.itemType)

      if (item.itemSubtype) {
        let set = itemSubtypeMap[item.itemType]
        if (!set) {
          set = new Set()
          itemSubtypeMap[item.itemType] = set
        }
        set.add(item.itemSubtype)
      }
    }

    return {
      attunementType: Array.from(attunementTypeSet).sort(),
      itemType: Array.from(itemTypeSet).sort(),
      itemSubtype: Object.entries(itemSubtypeMap).reduce((ret, curr) => {
        ret[curr[0]] = Array.from(curr[1]).sort()
        return ret
      }, {} as SaddlebagFilterOptions['itemSubtype']),
    }
  },
)

export const getVisibleItemSubtypeOptions = createSelector(
  [getFilterOptions, getFilters],
  (baseOptions, selectedFilters): string[] => {
    const visibleSubtypes: string[] = []

    for (const filter of selectedFilters.itemType) {
      const subtypes = baseOptions.itemSubtype[filter]
      if (!subtypes) continue
      visibleSubtypes.push(...subtypes)
    }

    return visibleSubtypes.sort()
  },
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const getAppliedSubtypeFilter = createSelector(
  [getFilters, getVisibleItemSubtypeOptions],
  (filters, visibleSubtypes): string[] => {
    return filters.itemSubtype.filter(s => visibleSubtypes.includes(s))
  },
)

export const allItemNames = createSelector([getListContents], contents =>
  contents ? contents.map(i => ({id: i.id, name: i.name})) : [],
)

export const getVisibleItems = createSelector(
  [getListContents, getFilters],
  (contents, filters): GriffonItem[] => {
    if (!contents) return []

    const mustMatchBy: Set<typeof filters['tagSearch'][0]['type']> = new Set(
      filters.tagSearch.map(t => t.type),
    )

    if (mustMatchBy.size === 0) return contents
    //   return contents.filter(itemViewableByPayTier(isAdmin))

    return contents.filter(item => {
      let textMatches = !(
        mustMatchBy.has('text:nameAndMeta') ||
        mustMatchBy.has('text:description') ||
        mustMatchBy.has('text:flavour')
      )
      let attunementMatches = !mustMatchBy.has('requiresAttunement')
      let itemTypeMatches = !mustMatchBy.has('itemType')
      let rarityMatches = !mustMatchBy.has('rarity')
      let isFreeMatches = !mustMatchBy.has('isFree')
      let significanceMatches = !mustMatchBy.has('significance')
      let cardVolumeMatches = !mustMatchBy.has('cardVolume')
      let bookVolumeMatches = !mustMatchBy.has('bookVolume')

      for (const term of filters.tagSearch) {
        // BEGIN TEXT SEARCH (name/meta OR flavour OR description)
        if (term.type === 'text:nameAndMeta') {
          const lowerTerm = term.value.toLowerCase()
          if (
            // Disable search by tag until admin can edit tags
            // (item.tags && (item.tags as string[]).includes(lowerTerm)) ||
            item.name.toLowerCase().includes(lowerTerm) ||
            item.itemType.toLowerCase().includes(lowerTerm) ||
            (item.itemSubtype &&
              item.itemSubtype.toLowerCase().includes(lowerTerm)) ||
            (typeof item.attunement === 'string' &&
              item.attunement.toLowerCase().includes(lowerTerm))
          )
            textMatches = true
        }
        if (!textMatches && term.type === 'text:flavour') {
          textMatches = Boolean(
            item.flavour?.toLowerCase().includes(term.value.toLowerCase()),
          )
        }
        if (!textMatches && term.type === 'text:description') {
          textMatches = item.description
            .toLowerCase()
            .includes(term.value.toLowerCase())
        }
        // END TEXT SEARCH

        if (term.type === 'requiresAttunement') {
          if (term.value === !!item.attunement) attunementMatches = true
        } else if (term.type === 'name') {
          if (term.value === item.name) textMatches = true
        } else if (term.type === 'itemType') {
          if (term.value === item.itemType) itemTypeMatches = true
        } else if (term.type === 'rarity') {
          if (term.value === '__multiple_rarities__')
            rarityMatches = rarityMatches || item.rarities.length > 1
          else
            rarityMatches = rarityMatches || item.rarities.includes(term.value)
        } else if (term.type === 'isFree') {
          if (term.value === !!item.free) isFreeMatches = true
        } else if (term.type === 'significance') {
          if (term.value === item.significance) significanceMatches = true
        } else if (term.type === 'cardVolume') {
          if (term.value === item.cardVolume) cardVolumeMatches = true
        } else if (term.type === 'bookVolume') {
          if (term.value === item.bookVolume) bookVolumeMatches = true
        }
      }

      return (
        attunementMatches &&
        itemTypeMatches &&
        rarityMatches &&
        isFreeMatches &&
        significanceMatches &&
        textMatches &&
        bookVolumeMatches &&
        cardVolumeMatches
      )
    })
  },
)

const transformItemWithRarityForSortingHat = (
  item: GriffonItem,
  rarity: Rarity,
): SortingHatItem => ({
  rarity: rarity,
  consumable:
    item.itemType === 'Potion' ||
    item.itemType === 'Scroll' ||
    Boolean(item.itemSubtype?.match(/(ammunition|arrow|bolt)/i)),
  cursed: item.description.includes('**Curse.**'),
  id: item.id,
  name: item.name,
  attunement: item.attunement,
  itemType: item.itemType,
  itemSubtype: item.itemSubtype,
  patreonArtUrl: item.patreonArtUrl,
  patreonCardUrl: item.patreonCardUrl,
  significance: item.significance,
})

export const getSortingHatsForEntireLedger = createSelector(
  [getContents, getFilters, getListId],
  (items, filters, listId): Array<{sortingHat: SortingHat; title: string}> => {
    // Use this line instead, and replace `getContents` with `getVisibleItems`
    // to generate tables regardless of filters
    // if (!items || listId !== 'home') return []
    if (!items || filters.tagSearch.length > 0 || listId !== 'home') return []

    const tableDefs: Array<{
      title: string
      rarity: Rarity
      subrarity?: Subrarity
    }> = [
      {title: 'Table A', rarity: 'common'},
      {title: 'Table B', rarity: 'uncommon', subrarity: 'minor'},
      {title: 'Table C', rarity: 'rare', subrarity: 'minor'},
      {title: 'Table D', rarity: 'very rare', subrarity: 'minor'},
      {title: 'Table E', rarity: 'legendary', subrarity: 'minor'},
      {title: 'Table F', rarity: 'uncommon', subrarity: 'major'},
      {title: 'Table G', rarity: 'rare', subrarity: 'major'},
      {title: 'Table H', rarity: 'very rare', subrarity: 'major'},
      {title: 'Table I', rarity: 'legendary', subrarity: 'major'},
    ]

    return tableDefs.map(({rarity, subrarity, title}) => {
      const sortingHatItems = items
        .filter(
          i =>
            i.rarities.includes(rarity) &&
            (subrarity === undefined || i.significance === subrarity),
        )
        .map(i => transformItemWithRarityForSortingHat(i, rarity))
      const sortingHat = new SingleRarityHat(sortingHatItems)
      return {title, sortingHat}
    })
  },
)

export const getSortingHatForVisibleItems = createSelector(
  [getVisibleItems, getFilters],
  (items, {tagSearch}) => {
    const filteredRarities = tagSearch
      .filter(
        (t): t is ISearchTag<'rarity', Rarity> =>
          t.type === 'rarity' && t.value !== '__multiple_rarities__',
      )
      .map(t => t.value)

    const HatClass =
      filteredRarities.length === 1 ? SingleRarityHat : WeightedHat

    return new HatClass(
      items.flatMap(i =>
        i.rarities
          .filter(
            r => filteredRarities.length === 0 || filteredRarities.includes(r),
          )
          .map(r => transformItemWithRarityForSortingHat(i, r)),
      ),
    )
  },
)

const RARITIES: Rarity[] = [
  'common',
  'uncommon',
  'rare',
  'very rare',
  'legendary',
  'artifact',
]

type ItemComparator = (a: GriffonItem, b: GriffonItem) => number

const compareOnRarity: ItemComparator = (a, b) => {
  const aRarities = new Set(a.rarities)
  const bRarities = new Set(b.rarities)

  for (const r of RARITIES) {
    const aHas = aRarities.delete(r)
    const bHas = bRarities.delete(r)

    const aRemaining = aRarities.size
    const bRemaining = bRarities.size

    if (aRemaining !== bRemaining) {
      if (aRemaining === 0) return -1
      else if (bRemaining === 0) return 1
    }

    if (aHas !== bHas) {
      if (aHas) return -1
      else return 1
    }
  }
  return 0
}

const compareOnType: ItemComparator = (a, b) => {
  const typeMatch = a.itemType.localeCompare(b.itemType)
  if (!typeMatch) {
    const aHasSubtype = !!a.itemSubtype
    const bHasSubtype = !!b.itemSubtype
    if (aHasSubtype !== bHasSubtype) {
      if (!aHasSubtype) return -1
      else if (!bHasSubtype) return 1
    }

    if (a.itemSubtype && b.itemSubtype) {
      return a.itemSubtype.localeCompare(b.itemSubtype)
    }
  }
  return typeMatch
}

const compareOnAvailability: ItemComparator = (a, b) => {
  return a.free ? (b.free ? 0 : -1) : b.free ? 1 : 0
}

const compareOnSignificance: ItemComparator = (a, b) => {
  return a.significance
    ? b.significance
      ? a.significance.localeCompare(b.significance) * -1
      : 1
    : b.significance
    ? -1
    : 0
}

const compareOnAttunement: ItemComparator = (a, b) => {
  const attunementA = a.attunement
  const attunementB = b.attunement

  if (typeof attunementA === typeof attunementB) {
    if (typeof attunementA === 'string' && typeof attunementB === 'string') {
      return attunementA.localeCompare(attunementB)
    } else if (
      typeof attunementA === 'boolean' &&
      typeof attunementB === 'boolean'
    ) {
      return attunementA ? (attunementB ? 0 : -1) : attunementB ? 1 : 0
    }
  }

  if (attunementA === true) {
    return -1
  } else if (attunementA === false) {
    return 1
  } else {
    return attunementB ? 1 : -1
  }
}

const compareOnDate: ItemComparator = (a, b) => {
  return sortableItemDate(a).localeCompare(sortableItemDate(b))
}

const comparatorForColumn = (column: SortColumn) =>
  ({
    rarity: compareOnRarity,
    type: compareOnType,
    free: compareOnAvailability,
    significance: compareOnSignificance,
    attunement: compareOnAttunement,
    date: compareOnDate,
    name: null,
  }[column])

export const getSortedVisibleItems = createSelector(
  [getSortColumn, getSortDirection, getVisibleItems],
  (sortColumn, sortDirection, items) => {
    const comparator = comparatorForColumn(sortColumn)
    return [...items].sort((a, b) => {
      let result = comparator ? comparator(a, b) : 0
      if (!result) {
        result = a.name.localeCompare(b.name)
        if (comparator) {
          // if using name as secondary sort, always ascending alphabetical
          result = result * sortDirection
        }
      }
      return result * sortDirection
    })
  },
)

export const itemsById = createSelector([getContents], contents => {
  const result: {[id: string]: GriffonItem} = {}
  if (!contents) return result
  for (const item of contents) {
    result[item.id] = item
  }
  return result
})

export const selectedItem = createSelector(
  [getSelectedItemId, itemsById],
  (id, items) => {
    if (!id) return null
    return items[id] || null
  },
)

export const selectedVisibleItemIndex = createSelector(
  [getSelectedItemId, getSortedVisibleItems],
  (id, items) => {
    if (!id || !items) return null
    const index = items.findIndex(item => item.id === id)
    return index === -1 ? null : index
  },
)

export const getSortedVisibleItemsMinusSelectedBundle = createSelector(
  [getSortedVisibleItems, getSelectedBundleItems],
  (contents, bundleItems) => {
    return contents.filter(i => !bundleItems.includes(i))
  },
)

export const getLootTableFilename = createSelector(
  [getSelectedBundle, getListId],
  (bundle, listId): string | undefined => {
    if (listId !== 'stash' || bundle === null) return undefined

    return bundle.name.replaceAll(/[^a-zA-Z0-9]/g, '') + '_LootTable'
  },
)
