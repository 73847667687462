import React from 'react'
import {Route, Redirect, RouteProps} from 'react-router-dom'

interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean
  isVerifying: boolean
  isAdmin?: boolean
  adminOnly?: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  isAdmin,
  adminOnly,
  ...rest
}: ProtectedRouteProps) => {
  const {children, ...restMinusChildren} = rest

  return (
    <Route
      {...restMinusChildren}
      render={props =>
        isVerifying ? (
          <div />
        ) : isAuthenticated ? (
          adminOnly && !isAdmin ? (
            <Redirect to="/" />
          ) : (
            children || (Component ? <Component {...props} /> : null)
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
