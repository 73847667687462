import './custom.scss'

import * as Sentry from '@sentry/browser'
import {BrowserTracing} from '@sentry/tracing'

import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'

import App from './TheApp'
import * as serviceWorker from './serviceWorker'
import {configureStore} from './store'

Sentry.init({
  dsn:
    'https://f68c7b1365c241bb941512630a1e4d0f@o4504011047763968.ingest.sentry.io/4504011055038464',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
})

console.debug('React.version', React.version)

const store = configureStore()
const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  rootElement,
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
