import React from 'react'
import {connect} from 'react-redux'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {setAndRememberFreeColumnVisible} from '../actions/thunks'
import {RootState} from '../store/types'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const options: [boolean, string][] = [
  [true, 'Show'],
  [false, 'Hide'],
]

const mapStateToProps = (state: RootState) => ({
  visible: state.ui.freeColumnVisible,
})

const FreeColumnToggle: React.FC<Props> = ({visible, setVisible}) => {
  return (
    <ButtonGroup>
      {options.map(([visibility, description], i) => (
        <Button
          key={i}
          active={visibility === visible}
          onClick={() => setVisible(visibility)}
          variant="outline-primary"
          size="sm"
        >
          {description}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default connect(mapStateToProps, {
  setVisible: setAndRememberFreeColumnVisible,
})(FreeColumnToggle)
