import Showdown, {ShowdownOptions} from 'showdown'

export const showdownOptions: ShowdownOptions = {
  tables: true,
  simpleLineBreaks: true,
}

export const htmlForMarkdownField = (markdown?: string) => {
  if (markdown === undefined) return markdown

  return new Showdown.Converter(showdownOptions).makeHtml(markdown)
}
