import React, {MouseEvent} from 'react'

import Overlay, {OverlayProps} from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

type CustomOverlayProps = Pick<
  OverlayProps,
  'show' | 'target' | 'onHide' | 'placement'
>

export interface StashMenuProps {
  onClickDelete?: () => void
  onClickDuplicate?: () => void
  onClickRename?: () => void
}

const StashMenuTooltip: React.FC<StashMenuProps &
  CustomOverlayProps> = props => {
  const handleMenuClick = (handler?: () => void) => (e: MouseEvent) => {
    e.stopPropagation()
    handler?.()
    props.onHide?.((e as unknown) as Event)
  }

  return (
    <Overlay
      onHide={props.onHide}
      rootClose
      show={props.show}
      target={props.target}
      placement={props.placement}
    >
      <Tooltip className="tooltip-white" id="stash-preview-menu">
        <ButtonGroup vertical>
          <Button
            variant="link"
            className="btn-plain-text-dark"
            onClick={handleMenuClick(props.onClickRename)}
          >
            Rename
          </Button>
          <Button
            variant="link"
            className="btn-plain-text-dark"
            onClick={handleMenuClick(props.onClickDuplicate)}
          >
            <span className="text-reset">Duplicate</span>
          </Button>
          <Button
            variant="link"
            className="btn-plain-text-primary"
            onClick={handleMenuClick(props.onClickDelete)}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Tooltip>
    </Overlay>
  )
}

export default StashMenuTooltip
