import React, {useState} from 'react'

import {ItemBundle} from '../../store/types'
import {StoreDispatch} from '../../store'
import {thunks} from '../../actions'

import Dialog from '../Dialog'

interface Options {
  onStashDelete?: (stash: ItemBundle) => void
  onStashDuplicate?: (stash: ItemBundle) => void
  onStashRename?: (stash: ItemBundle) => void
}

export default (
  dispatch: StoreDispatch,
  options: Options,
): {
  deleteStash: (stash: ItemBundle | null) => void
  renameStash: (stash: ItemBundle | null) => void
  duplicateStash: (stash: ItemBundle) => Promise<void>
  render: () => JSX.Element
} => {
  const [deletingStash, setDeletingStash] = useState<ItemBundle | null>(null)
  const [renamingStash, setRenamingStash] = useState<ItemBundle | null>(null)

  return {
    deleteStash: setDeletingStash,
    renameStash: setRenamingStash,
    duplicateStash: async (stash: ItemBundle) => {
      await dispatch(thunks.duplicateBundle(stash))
      options.onStashDuplicate?.(stash)
    },
    render: () => {
      const StashManagementDialogs = (
        <>
          <Dialog
            title={`Delete "${deletingStash?.name}"?`}
            submitTitle="Delete"
            show={deletingStash !== null}
            exit={() => setDeletingStash(null)}
            onSubmit={async () => {
              const stash = deletingStash
              // TODO: make this null AFTER dialog has fully hidden
              setDeletingStash(null)
              if (stash) {
                await dispatch(thunks.deleteBundle(stash))
                options.onStashDelete?.(stash)
              }
            }}
          />

          <Dialog
            title={`Rename Stash`}
            prompt
            initialPromptValue={renamingStash?.name}
            submitTitle="Done"
            show={renamingStash !== null}
            exit={() => setRenamingStash(null)}
            onSubmit={async value => {
              if (!value) return
              const stash = renamingStash
              // TODO: make this null AFTER dialog has fully hidden
              setRenamingStash(null)
              if (stash) {
                await dispatch(thunks.renameBundle(stash, value))
                options.onStashRename?.(stash)
              }
            }}
          />
        </>
      )
      return StashManagementDialogs
    },
  }
}
