import React, {Fragment, useEffect, useState} from 'react'
import {collection, getDocs, or, query, where} from 'firebase/firestore'
import {db} from '../../../firebase/firebase'
import {GriffonDatabaseItem, GriffonItem} from '../../../store/types'
import {
  fullItemType,
  itemMetaString,
  rarityLetterForItem,
  shortFormatSubrarity,
  sortableItemDate,
} from '../../../utils'
import ItemDescription from '../../ItemDescription'
import {setDerivedPropertiesForItem} from '../../../store/items'

const quote = (s: string) => `"${s.replace(/"/g, '""')}"`
const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1)

const generateTable = (items: GriffonItem[]): string => {
  type Row = {
    rarity_attunement: string
    name: string
    type_subType: string
    rarity: string
    subRarity: string
    attunement: string
  }

  const uniqueRarityItems = items.flatMap(item =>
    item.rarities.map(rarity => ({...item, rarity, rarities: [rarity]})),
  )

  const rows: Row[] = uniqueRarityItems.map(item => ({
    rarity_attunement: rarityLetterForItem(item) + (item.attunement ? '*' : ''),
    name: item.name,
    type_subType: fullItemType(item),
    rarity: capitalize(item.rarity),
    subRarity: shortFormatSubrarity(item),
    attunement:
      item.attunement === false
        ? 'No'
        : item.attunement === true
        ? 'Yes'
        : `Yes, ${item.attunement}`,
  }))

  const headers: Array<keyof Row> = [
    'rarity_attunement',
    'name',
    'type_subType',
    'rarity',
    'subRarity',
    'attunement',
  ]

  return [
    headers.map(quote).join(','),
    ...rows.map(row =>
      headers
        .map(header => row[header])
        .map(quote)
        .join(','),
    ),
  ].join('\n')
}

export const BookExport = () => {
  const [items, setItems] = useState<GriffonItem[]>([])
  useEffect(() => {
    const getItems = async () => {
      const itemCollection = collection(db, 'items')
      const q = query(
        itemCollection,
        or(
          where('name', '==', "The Griffon's Saddlebag"),
          where('bookAccessCodeGrant', '==', 'book_3'),
        ),
      )
      const docs = (await getDocs(q)).docs
      console.log('docs', docs)
      return docs
        .map(doc => doc.data() as GriffonDatabaseItem)
        .map(item => setDerivedPropertiesForItem(item))
        .sort((a, b) => sortableItemDate(a).localeCompare(sortableItemDate(b)))
    }

    getItems().then(docs => setItems(docs))
  }, [])

  const contentRef = React.createRef<HTMLElement>()

  return (
    <Fragment>
      <button
        onClick={() => {
          const el = contentRef.current
          if (el) {
            const content = el.cloneNode(true) as HTMLElement
            // Export the items with the first paragraph of each item having an h3 tag around it
            // The first paragraph of each thing I do isn't indented, but the rest are, so it'd
            // be awesome if we could style those first paragraphs differently from the rest.
            content.querySelectorAll('div > div > p:first-child').forEach(p => {
              const h3 = document.createElement('h3')
              h3.innerHTML = p.innerHTML
              p.parentNode?.replaceChild(h3, p)
            })
            navigator.clipboard.writeText(content.outerHTML)
          }
        }}
      >
        Convert and copy HTML
      </button>

      <button
        onClick={() => {
          navigator.clipboard.writeText(generateTable(items))
        }}
      >
        Copy table CSV
      </button>

      <main ref={contentRef}>
        {items.map(i => (
          <BookItem key={i.id} item={i} />
        ))}
      </main>
    </Fragment>
  )
}

const BookItem = ({item}: {item: GriffonDatabaseItem}) => {
  return (
    <section>
      <h1>{item.name}</h1>
      <h2>{itemMetaString(item)}</h2>
      <ItemDescription omitStyle markup={item.description} />
    </section>
  )
}
