import JSZip from 'jszip'

import {getImageUrl} from '../../fileStorage'
import {htmlForMarkdownField} from '../../markdown'
import {GriffonItem} from '../../store/types'

const imageFilenameForItem = (item: GriffonItem): string => {
  return item.name.toLowerCase().replace(/[^a-zA-Z]+/g, '') + '.png'
}

const exportFoundryItems = async (
  items: GriffonItem[],
  filename = 'export',
) => {
  const zip = new JSZip()

  const imageFolder = zip.folder('images')
  if (imageFolder) {
    await Promise.all(
      items.map(async item => {
        const imageId = item.imageIds?.[0]
        if (!imageId) return
        const imageUrl = await getImageUrl(imageId, 'medium')
        if (!imageUrl) return

        const response = await fetch(imageUrl)

        imageFolder.file(imageFilenameForItem(item), response.blob())
      }),
    )
  }

  zip.file('metadata.json', foundryJsonForItems(items))

  zip
    .generateAsync({type: 'blob'})
    .then(content => saveAs(content, `${filename}.zip`))
}

const foundryJsonForItems = (items: GriffonItem[]): string => {
  const results = items.map(item => ({
    name: item.name,
    image: imageFilenameForItem(item),
    attunement: item.attunement,
    dateMonth: item.dateMonth !== undefined ? item.dateMonth + 1 : undefined,
    dateYear: item.dateYear,
    itemType: item.itemType,
    rarity: item.rarity,
    description: htmlForMarkdownField(item.description),
    flavour: htmlForMarkdownField(item.flavour),
  }))

  return JSON.stringify(results, undefined, 2)
}

export default exportFoundryItems
