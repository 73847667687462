import {
  DateColumnMode,
  DateFormat,
  GriffonDatabaseItem,
  GriffonItem,
} from './store/types'
import {DateTime} from 'luxon'

export const fullItemType = (
  item: Pick<GriffonItem, 'itemType' | 'itemSubtype'>,
): string =>
  item.itemSubtype ? `${item.itemType} (${item.itemSubtype})` : item.itemType

export const capitalizedRarity = (rarity: string) =>
  rarity.substring(0, 1).toUpperCase() + rarity.substring(1)

export const itemMetaString = (
  item: Pick<GriffonItem, 'itemType' | 'itemSubtype' | 'rarity' | 'attunement'>,
): string => {
  const parts = []
  parts.push(fullItemType(item), item.rarity)

  let attunement = ''
  if (item.attunement) {
    attunement = ' (requires attunement'
    if (typeof item.attunement === 'string') {
      attunement += ' ' + item.attunement
    }
    attunement += ')'
  }

  return parts.join(', ') + attunement
}

export const sortableItemDate = (item: GriffonDatabaseItem): string =>
  DateTime.utc(
    item.dateYear ?? 2000,
    (item.dateMonth ?? 0) + 1,
    item.dateDay ?? 1,
  ).toISODate()

export const prettyItemDate = (item: GriffonItem, format: DateFormat): string =>
  item.dateMonth !== undefined && item.dateYear !== undefined
    ? DateTime.local(
        item.dateYear,
        item.dateMonth + 1,
        item.dateDay ?? 1,
      ).toFormat(format === 'short' ? 'LLL ʼyy' : 'LLL d ʼyy')
    : '???'

export const shortFormatSubrarity = (item: GriffonItem): string => {
  switch (item.significance) {
    case 'major':
      return 'M'
    case 'minor':
      return 'm'
  }
  return ''
}

export const formatSubrarity = (item: GriffonItem): string => {
  switch (item.significance) {
    case 'major':
      return 'Major'
    case 'minor':
      return 'Minor'
  }
  return ''
}

export const rarityLetterForItem = (item: GriffonItem) => {
  if (item.rarities.includes('common')) return 'C'
  if (item.rarities.includes('uncommon')) return 'U'
  if (item.rarities.includes('rare')) return 'R'
  if (item.rarities.includes('very rare')) return 'V'
  if (item.rarities.includes('legendary')) return 'L'
  if (item.rarities.includes('artifact')) return 'A'

  return 'X'
}

export const dateModeLabel = (mode: DateColumnMode) => {
  switch (mode) {
    case 'book':
      return 'Book'
    case 'card':
      return 'Card Vol.'
    default:
      return 'Date'
  }
}
