import React, {useRef, useState, FormEvent} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import {myFirebase} from '../firebase/firebase'
import {handleAccessCodeClaimSuccess} from '../actions/thunks'

import Layout from './Layout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiceD20} from '@fortawesome/free-solid-svg-icons'
import {getFunctions, httpsCallable} from 'firebase/functions'

interface Props {
  handleAccessCodeClaimSuccess: () => Promise<void>
}

const ClaimAccessCode: React.FC<Props> = props => {
  const claimAccessCode = httpsCallable(
    getFunctions(myFirebase),
    'claimAccessCode',
  )

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    if (isSubmitting) return
    const code = inputRef?.current?.value
    if (!code) return

    setSubmitting(true)

    try {
      await claimAccessCode(code)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      setSubmitting(false)
      alert(err.message)
      console.error(err)
      return
    }

    await props.handleAccessCodeClaimSuccess()
    history.push('/')
  }

  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Layout>
      <form onSubmit={submit}>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Enter your access code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl ref={inputRef} type="text" autoFocus />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <FontAwesomeIcon icon={faDiceD20} spin /> Loading&hellip;
                </>
              ) : (
                'Apply Code'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Layout>
  )
}

export default connect(null, {handleAccessCodeClaimSuccess})(ClaimAccessCode)
