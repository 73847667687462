import React from 'react'
import {Async} from 'react-async'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import Table from 'react-bootstrap/Table'

import {AccessCode, WithId} from '../../store/types'
import {db} from '../../firebase/firebase'
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore'
import Layout from '../Layout'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadCodes = async (props: any): Promise<Array<AccessCode & WithId>> => {
  return (
    await getDocs(
      query(
        collection(db, 'accessCodes'),
        where('batchId', '==', props.batchId),
        orderBy('generatedAt', 'desc'),
      ),
    )
  ).docs.map(d => ({...(d.data() as AccessCode), id: d.id}))
}

const ListCodes: React.FC<RouteComponentProps<{batchId: string}>> = ({
  match,
}) => {
  const batchId = match.params.batchId

  return (
    <Layout>
      <h4>
        Access Codes for Batch <code>{batchId}</code>
      </h4>

      <Async promiseFn={loadCodes} batchId={batchId}>
        {({data, error, isPending}) => {
          if (isPending) return 'Loading...'
          if (error) return `Something went wrong: ${error.message}`
          if (data)
            return (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Claimed at</th>
                    <th>User ID</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(code => (
                    <tr key={code.id}>
                      <td>
                        <code>{code.id}</code>
                      </td>
                      <td>{code.claimedAt?.toDate().toLocaleString()}</td>
                      <td>{code.claimedBy}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )
          return null
        }}
      </Async>
    </Layout>
  )
}

export default withRouter(ListCodes)
