import {
  ELITE_TIER_ID,
  ESTEEMED_TIER_ID,
  HERO_TIER_ID,
  LEGENDARY_TIER_ID,
  TIER_IDS_TO_CENTS,
} from './sharedConstants'
import {UserState} from './store/types'

export const patronTitleForPledgeCents = (
  pledgeCents: number,
): string | null => {
  const levels: [number, string][] = [
    [TIER_IDS_TO_CENTS[LEGENDARY_TIER_ID], 'Legendary Hero'],
    [TIER_IDS_TO_CENTS[ESTEEMED_TIER_ID], 'Esteemed Hero'],
    [TIER_IDS_TO_CENTS[ELITE_TIER_ID], 'Elite Hero'],
    [TIER_IDS_TO_CENTS[HERO_TIER_ID], 'Hero'],
  ]

  for (const [levelCents, levelTitle] of levels) {
    if (pledgeCents >= levelCents) return levelTitle
  }

  return null
}

const toCurrency = (cents: number): string => {
  const dollars = cents / 100
  if (cents % 100 === 0) return '$' + dollars.toString()
  return '$' + dollars.toFixed(2)
}

export const patronDescriptionForUser = (user: UserState): string | null => {
  const cents = user.patreonEntitledAmountCents

  const patreonTitle = patronTitleForPledgeCents(cents)

  if (user.invitedBy) {
    const titleStartsWithVowel = /^[aeiou]/i.test(patreonTitle || '')
    return `Invited by ${titleStartsWithVowel ? 'an' : 'a'} ${patreonTitle}`
  }
  if (cents) {
    return `${toCurrency(cents)}: ${patreonTitle}`
  }

  return null
}
