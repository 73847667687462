import React from 'react'
import classnames from 'classnames'
import styles from './Square.module.css'

interface Props {
  className?: string
}

const Square: React.FC<Props> = ({children, className}) => (
  <div className={styles.square}>
    <div className={classnames(styles.squarecontent, className)}>
      {children}
    </div>
  </div>
)

export default Square
