import React from 'react'
import SVG from 'react-inlinesvg'

const SvgIcon: React.FC<{
  iconName: string
  size?: number
  fixedWidth?: boolean
}> = ({iconName, size = 20, fixedWidth}) => (
  <span
    style={{
      display: 'inline-block',
      width: size,
      height: size,
      margin: fixedWidth ? '0 3px' : undefined,
    }}
  >
    <SVG
      src={`/images/icon-${iconName}.svg`}
      fill="currentColor"
      style={{verticalAlign: 'top'}}
    />
  </span>
)

export default SvgIcon
