import {
  RootState,
  ListIdentifier,
  SortColumn,
  SortDirection,
  ItemFilterState,
} from '../store/types'

export const getSortColumn = (
  state: RootState,
  listId: ListIdentifier,
): SortColumn => state.listViews[listId].sortColumn
export const getSortDirection = (
  state: RootState,
  listId: ListIdentifier,
): SortDirection => state.listViews[listId].sortDirection
export const getFilters = (
  state: RootState,
  listId: ListIdentifier,
): ItemFilterState => state.listViews[listId].filters
