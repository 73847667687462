import React, {useState, useEffect} from 'react'

import {withRouter, RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux'
import FormControl from 'react-bootstrap/FormControl'
import 'firebase/firestore'

import Filters from './Filters'
import Layout from './Layout'
import ItemGrid from './ItemGrid'
import {ItemBundle, GriffonItem, RootState, UserState} from '../store/types'
import {actions, thunks} from '../actions'
import {
  getSelectedBundleItems,
  getSortedVisibleItemsMinusSelectedBundle,
  getSelectedBundle,
} from '../selectors'
import {StoreDispatch} from '../store'
import FullWidthContainer from './LimitedMaxWidthContainer'
import ScrollToTopOnMount from './ScrollToTopOnMount'

interface Props extends RouteComponentProps<{bundleId: string | undefined}> {
  bundle: ItemBundle | null
  setBundle: (bundleId: string) => void
  setBundleName: (name: string) => void
  addItemToBundle: (item: GriffonItem) => void
  removeItemFromBundle: (itemId: string) => void
  bundleItems: GriffonItem[]
  visibleItems: GriffonItem[]
  user: null | UserState
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  bundle: getSelectedBundle(state),
  bundleItems: getSelectedBundleItems(state),
  visibleItems: getSortedVisibleItemsMinusSelectedBundle(state, 'stash'),
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setBundle: (bundleId: string) =>
    dispatch(actions.setSelectedBundle(bundleId)),
  setBundleName: (name: string) => dispatch(thunks.setBundleName(name)),
  addItemToBundle: (item: GriffonItem) =>
    dispatch(thunks.addItemToBundle(item.id)),
  removeItemFromBundle: (itemId: string) =>
    dispatch(thunks.removeItemFromBundle(itemId)),
})

const BundleView: React.FC<Props> = props => {
  const {
    match,
    bundleItems,
    visibleItems,
    addItemToBundle,
    removeItemFromBundle,
    setBundle,
    setBundleName,
    bundle,
  } = props
  const loading = !bundle
  const [name, setName] = useState(bundle?.name || '')

  useEffect(() => {
    if (match.params.bundleId) setBundle(match.params.bundleId)
  }, [match.params.bundleId, setBundle])

  useEffect(() => {
    if (bundle) setName(bundle.name)
  }, [bundle])

  return (
    <Layout fullWidth>
      <ScrollToTopOnMount />
      <FullWidthContainer>
        <br />

        <FormControl
          size="lg"
          disabled={loading}
          placeholder={loading ? 'Loading' : 'Name your bundle'}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.currentTarget.value)
          }
          onBlur={() => setBundleName(name)}
        />

        {bundleItems.length > 0 && <hr />}

        <ItemGrid
          isAdmin={false}
          items={bundleItems}
          setSelectedItem={item => removeItemFromBundle(item.id)}
        />

        <hr />

        <Filters listId="stash" navigateToItem={() => null} />
        <hr />
        <ItemGrid
          isAdmin={false}
          items={visibleItems}
          setSelectedItem={addItemToBundle}
        />
      </FullWidthContainer>
    </Layout>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BundleView))
