import React from 'react'
import Image from 'react-bootstrap/Image'
import {useAsync, PromiseFn} from 'react-async'

import {externalLinkProps} from './externalLinks'
import {getCachedImageUrl, getImageUrl} from '../fileStorage'

interface Props {
  imageId: string
  link?: boolean
  thumbnail?: boolean
  fullWidth?: boolean
  size: 'original' | 'tiny' | 'small' | 'medium' | 'large'
  dimension?: number
}

const asyncGetUrl: PromiseFn<string | null> = async ({imageId, size}) => {
  const url = await getImageUrl(imageId, size)
  return url
}

const ImageDisplay: React.FunctionComponent<Props> = ({
  imageId,
  link,
  size,
  thumbnail,
  dimension,
  fullWidth,
}) => {
  const cachedUrl = getCachedImageUrl(imageId, size)

  const {data: url} = useAsync<string | null>({
    promiseFn: asyncGetUrl,
    imageId,
    size,
  })

  const imageUrl = cachedUrl || url

  const image = (
    <Image
      style={fullWidth ? {width: '100%'} : undefined}
      fluid={!dimension}
      width={dimension}
      height={dimension}
      thumbnail={thumbnail !== false}
      src={
        imageUrl ||
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      }
    />
  )

  return link ? (
    <a href={imageUrl} {...externalLinkProps}>
      {image}
    </a>
  ) : (
    image
  )
}

export default ImageDisplay
