import React from 'react'
import {connect} from 'react-redux'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {setAndRememberDateFormat} from '../actions/thunks'
import {DateFormat, RootState} from '../store/types'

interface Props {
  format: DateFormat
  setFormat: (f: DateFormat) => void
}

const options: [DateFormat, string][] = [
  ['short', 'Compact'],
  ['long', 'Full'],
]

const mapStateToProps = (state: RootState) => ({
  format: state.ui.dateFormat,
})

const DateFormatToggle: React.FC<Props> = ({format, setFormat}) => {
  return (
    <ButtonGroup>
      {options.map(([f, description], i) => (
        <Button
          key={i}
          active={f === format}
          onClick={() => setFormat(f)}
          variant="outline-primary"
          size="sm"
        >
          {description}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default connect(mapStateToProps, {
  setFormat: setAndRememberDateFormat,
})(DateFormatToggle)
