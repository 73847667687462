import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAnalytics} from 'firebase/analytics'
import {getStorage} from 'firebase/storage'

const firebaseConfig =
  process.env.REACT_APP_FIREBASE_PROJECT === 'sandbox'
    ? require('./config/sandbox.json')
    : require('./config/prod.json')

if (process.env.NODE_ENV === 'development') {
  console.log(process.env)
  console.log({firebaseConfig})
}

export const myFirebase = initializeApp(firebaseConfig)
if (process.env.NODE_ENV === 'production') {
  getAnalytics(myFirebase)
}
export const db = getFirestore(myFirebase)

export const storage = getStorage(myFirebase)
