import React from 'react'
import {connect} from 'react-redux'
import {actions} from '../actions'
import {StoreDispatch} from '../store'

import {DateColumnMode, RootState} from '../store/types'
import {dateModeLabel} from '../utils'
import {DateColumnModeButton} from './DateColumnModeButton'

interface Props {
  dateColumnMode: DateColumnMode
  setDateColumnMode: (mode: DateColumnMode) => void
}

const DateModeLabel: React.FC<Props> = ({
  dateColumnMode,
  setDateColumnMode,
}) => (
  <>
    <DateColumnModeButton
      onClick={nextMode => setDateColumnMode(nextMode)}
      mode={dateColumnMode}
    />
    {dateModeLabel(dateColumnMode)}
  </>
)

export default connect(
  (state: RootState, ownProps: Partial<Props>) => ({
    dateColumnMode:
      ownProps.dateColumnMode || state.listViews.home.dateColumnMode,
  }),
  (dispatch: StoreDispatch, ownProps: Partial<Props>) => ({
    setDateColumnMode:
      ownProps.setDateColumnMode ||
      ((mode: DateColumnMode) =>
        dispatch(actions.uiSetDateColumnMode(mode, 'home'))),
  }),
)(DateModeLabel)
