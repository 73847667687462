import React, {Component, ChangeEventHandler, FormEventHandler} from 'react'

import {connect} from 'react-redux'
import {
  Redirect,
  withRouter,
  RouteComponentProps,
  Route,
} from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

import {RootState} from '../store/types'
import {StoreDispatch} from '../store'
import {loginUser} from '../actions/thunks'

import Layout from './Layout'

interface Props
  extends RouteComponentProps<
    Record<string, string>,
    Record<string, string>,
    {from: {pathname: string}}
  > {
  loginError: boolean
  isAuthenticated: boolean
  isVerifying: boolean
  dispatch: StoreDispatch
}
interface State {
  email: string
}

class Login extends Component<Props, State> {
  state = {email: ''}

  handleEmailChange: ChangeEventHandler<HTMLInputElement> = ({target}) => {
    this.setState({email: target.value || ''})
  }

  handleSubmit: FormEventHandler = e => {
    e.preventDefault()

    const {dispatch} = this.props
    const {email} = this.state

    dispatch(loginUser(email))
  }

  render() {
    const {isAuthenticated, isVerifying, location, match} = this.props
    const {from} = location.state || {from: {pathname: '/'}}

    if (isAuthenticated) {
      return <Redirect to={from} />
    } else {
      return (
        <Layout>
          <Container>
            <br />
            {isVerifying ? (
              'Verifying&hellip;'
            ) : (
              <>
                <Route exact path={match.path}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Log In/Create Account</Card.Title>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={this.handleEmailChange}
                          />
                        </Form.Group>
                        <Card.Text>
                          Get a magic link sent to your email that&apos;ll sign
                          you in instantly!
                        </Card.Text>
                        <Form.Group>
                          <Button
                            type="submit"
                            variant="primary"
                            onClick={this.handleSubmit}
                          >
                            Send magic link
                          </Button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Route>
              </>
            )}
          </Container>
        </Layout>
      )
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoggingIn: state.auth.isLoggingIn,
  loginError: state.auth.loginError,
  isAuthenticated: state.auth.isAuthenticated,
  isVerifying: state.auth.isVerifying,
})

export default withRouter(connect(mapStateToProps)(Login))
