import React from 'react'
import classnames from 'classnames'

import styles from './StashPreview.module.scss'
import Square from '../Square'

interface Props {
  highlighted?: boolean
}

const StashThumbnailContainer: React.FC<Props> = ({children, highlighted}) => (
  <Square>
    <div
      className={classnames(styles.StashThumbnail__imagecontainer, {
        [styles['StashThumbnail__imagecontainer--highlighted']]: highlighted,
      })}
    >
      {children}
    </div>
  </Square>
)

export default StashThumbnailContainer
