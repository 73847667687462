import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

import {LootTable} from './items'
import {capitalizedRarity, fullItemType} from '../../utils'

const formatRangeMember = (dieSize: number) => (num: number): string => {
  const maxLength = dieSize === 100 ? 2 : 3
  return `${num}`.padStart(maxLength, '0').substr(-maxLength)
}

const rollExplanationForDieSize = (dieSize: number): string | null => {
  switch (dieSize) {
    case 200:
      return 'Roll d10 − 1, multiply by 20, add d20'
    case 400:
      return 'Roll d4 − 1, multiply by 100, add d100'
    case 600:
      return 'Roll d6 − 1, multiply by 100, add d100'
    case 800:
      return 'Roll d8 − 1, multiply by 100, add d100'
    case 1000:
      return 'Roll d10 − 1, multiply by 100, add d100'
  }

  return null
}

const downloadXlsx = async (
  workbook: ExcelJS.Workbook,
  filename: string,
): Promise<void> => {
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, `${filename}.xlsx`, {autoBom: false})
}

export const downloadXlsxForMultipleTables = async (
  entries: Array<{table: LootTable; title: string}>,
): Promise<void> => {
  const workbook = new ExcelJS.Workbook()

  for (const entry of entries) {
    const worksheet = workbook.addWorksheet(entry.title)
    populateWorksheetWithTable(entry.table, worksheet)
  }

  await downloadXlsx(workbook, 'TheGriffonsSaddlebag_LootTables_A-I')
}

export const downloadXlsxForTable = async (
  lootTable: LootTable,
  title = 'CustomLootTable',
): Promise<void> => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Loot Table')

  populateWorksheetWithTable(lootTable, worksheet)

  await downloadXlsx(workbook, title)
}

const populateWorksheetWithTable = (
  lootTable: LootTable,
  worksheet: ExcelJS.Worksheet,
): void => {
  const dieSize = lootTable.reduce((sum, row) => sum + row.size, 0)
  const headers = [
    `d${dieSize}`,
    'Item Name',
    'Type',
    'Rarity',
    'Sub',
    'Requires Attunement?',
    'Ledger+ URL',
    'Art Files',
    'Card Files',
  ]

  let rowStart = 1
  const rows = lootTable.map(row => {
    const {item} = row
    const rowEnd = rowStart + row.size - 1
    const rangeMembers = [rowStart]
    if (rowEnd !== rowStart) rangeMembers.push(rowEnd)
    const numLabel = rangeMembers.map(formatRangeMember(dieSize)).join('–')
    rowStart = rowEnd + 1
    return [
      numLabel,
      item.name,
      fullItemType(item),
      capitalizedRarity(item.rarity),
      item.significance === 'major' ? 'M' : 'm',
      item.attunement === false
        ? ''
        : item.attunement === true
        ? 'Yes'
        : `Yes, ${item.attunement}`,
      `https://ledger.thegriffonssaddlebag.com/items/${item.id}`,
      item.patreonArtUrl,
      item.patreonCardUrl,
    ]
  })

  worksheet.addRows([headers, ...rows])

  const diceExplanation = rollExplanationForDieSize(dieSize)
  if (diceExplanation) worksheet.getCell(1, 1).note = diceExplanation

  worksheet.getRow(1).eachCell(c => (c.style = {font: {bold: true}}))
}
