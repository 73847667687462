import {ThunkAction} from 'redux-thunk'
import shortid from 'shortid'

import {
  User as FirebaseUser,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
} from 'firebase/auth'
import {
  DocumentData,
  DocumentReference,
  Query,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {ref as storageRef, uploadBytes} from 'firebase/storage'

import {db, myFirebase, storage} from '../firebase/firebase'
import {
  currentUserCanSeeAllItems,
  currentUserHasBook1Access,
  currentUserHasBook2Access,
} from '../selectors'
import {bundlesById, getMyFaveBundle} from '../selectors/bundles'
import {
  ACCESS_CODE_GRANTS,
  DateFormat,
  DetailMode,
  GriffonDatabaseItem,
  GriffonItem,
  ItemBundle,
  MaybeUnsaved,
  RootState,
  SimpleFirebaseUser,
  UnsavedGriffonItem,
  UserState,
} from '../store/types'
import {AllActions, actions} from './standard'

const auth = getAuth(myFirebase)

type MyThunkAction<R> = ThunkAction<R, RootState, undefined, AllActions>

const generateCopyName = (name: string): string => {
  const numericSuffixPattern = /\s(\d+)$/
  const numericSuffixMatch = name.match(numericSuffixPattern)
  if (numericSuffixMatch) {
    const nextNumber = parseInt(numericSuffixMatch[1]) + 1
    return name.replace(numericSuffixPattern, ` ${nextNumber}`)
  }

  return name + ' 2'
}

export const getUserFromDB = async (
  user: FirebaseUser | UserState,
): Promise<UserState> => {
  let dbUser
  let retryCount = 0
  do {
    dbUser = (await getDoc(doc(db, 'users', user.uid))).data()

    // It's possible the user hasn't been created in the DB yet,
    // as its creation is triggered asynchronously by the creation
    // of the user in the Authentication system.
    // If this is the case, retry the query up to 10 times
    if (!dbUser) {
      retryCount += 1
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
  } while (!dbUser && retryCount < 10)

  const result: UserState = {
    ...user,
    isAdmin: false,
    patreonEntitledAmountCents: 0,
    patreonUserId: null,
    eligibilityStatusUpdatedAt: null,
    accessCodeGrants: [],
    patreonEliteHeroTier: undefined,
    invitedBy: null,
    invitedUserEmails: [],
  }

  if (dbUser) {
    result.isAdmin = dbUser.isAdmin === true
    result.patreonUserId = dbUser.patreonUserId || null

    if (
      typeof dbUser.patreonEntitledAmountCents === 'number' &&
      isFinite(dbUser.patreonEntitledAmountCents)
    ) {
      result.patreonEntitledAmountCents = dbUser.patreonEntitledAmountCents
    }

    if (dbUser.eligibilityStatusUpdatedAt) {
      result.eligibilityStatusUpdatedAt = dbUser.eligibilityStatusUpdatedAt.toDate()
    }

    if (dbUser.accessCodeGrants) {
      result.accessCodeGrants = dbUser.accessCodeGrants
    }

    if (dbUser.patreonEliteHeroTier !== undefined) {
      result.patreonEliteHeroTier = dbUser.patreonEliteHeroTier
    }

    if (dbUser.invitedBy) {
      result.invitedBy = dbUser.invitedBy
    }
    if (dbUser.invitedUsers) {
      result.invitedUserEmails = dbUser.invitedUsers.map(
        (user: {id: string; email: string}) => user.email,
      )
    }
  }

  return result
}

export const loginUser = (
  email: string,
): MyThunkAction<Promise<SimpleFirebaseUser | undefined>> => async dispatch => {
  window.localStorage.setItem('emailForSignIn', email)
  dispatch(actions.requestLogin())
  let user: UserState | undefined
  try {
    await sendSignInLinkToEmail(auth, email, {
      url: `https://${window.location.host}/login/link`,
      handleCodeInApp: true,
    })
  } catch (e) {
    console.error(e)
    dispatch(actions.loginFailed())
  }
  return user
}

export const logoutUser = (): MyThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch(actions.requestLogout())
  try {
    dispatch(actions.setSaddlebagContents([]))
    console.debug('logged out, calling clearCachedItems()')
    clearCachedItems()
    await signOut(auth)
    dispatch(actions.logoutSucceeded())
  } catch (e) {
    console.error(e)
    dispatch(actions.logoutFailed())
    dispatch(loadVisibleItems())
  }
}

export const verifyAuth = (): MyThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch(actions.requestVerify())
}

export const logInWithEmailLink = (): MyThunkAction<Promise<
  void
>> => async () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation') || ''
    }

    try {
      console.debug('about to signInWithEmailLink, calling clearCachedItems()')
      clearCachedItems()
      const emailLink = window.location.href
      if (!isSignInWithEmailLink(auth, emailLink)) {
        console.error('URL is not a signInWithEmailLink', emailLink)
        window.alert('URL is not a Magic Link')
      }
      await signInWithEmailLink(auth, email, emailLink)
      window.localStorage.removeItem('emailForSignIn')
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      console.error(err)
      if (err.code && err.message) {
        window.alert(`Login error (${err.code}): ${err.message}`)
      }
      throw e
    }
  }
}

const getLastItemFetchDate = (): Date | null => {
  const storedValue = window.localStorage.getItem('itemsFetchedAt')
  const numericValue = parseInt(storedValue || '')
  if (!isFinite(numericValue)) return null
  return new Date(numericValue * 1000)
}

const ITEM_CACHE_USER_ID_KEY = 'itemsCachedForUserId'

const getUserIdForCachedItems = (): string | null => {
  const storedValue = window.localStorage.getItem(ITEM_CACHE_USER_ID_KEY)
  if (!storedValue) return null
  return storedValue
}

type GriffonItemMap = {[key: string]: GriffonDatabaseItem}

const cacheItems = (
  items: GriffonItemMap,
  fetchedAt: Date,
  userId: string | null,
) => {
  const fetchedAtString = (fetchedAt.getTime() / 1000).toString()

  // This may fail due to storage quota limits
  // https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria
  try {
    console.log(
      `caching ${Object.keys(items).length} items at`,
      fetchedAt,
      'for user',
      userId,
    )
    window.localStorage.setItem('items', JSON.stringify(items))
    window.localStorage.setItem('itemsFetchedAt', fetchedAtString)
    window.localStorage.setItem(ITEM_CACHE_USER_ID_KEY, userId ?? '')
  } catch (e) {
    console.error('Unable to cache items', e)
    clearCachedItems()
  }
}

const clearCachedItems = () => {
  console.debug('clearing cached items')
  window.localStorage.removeItem('items')
  window.localStorage.removeItem('itemsFetchedAt')
  window.localStorage.removeItem(ITEM_CACHE_USER_ID_KEY)
}

const getCachedItems = (): GriffonItemMap => {
  const stringValue = window.localStorage.getItem('items')
  if (!stringValue) return {}
  try {
    return JSON.parse(stringValue)
  } catch {
    return {}
  }
}

export const loadVisibleItems = (): MyThunkAction<Promise<Array<
  GriffonDatabaseItem
> | null>> => async (dispatch, getState) => {
  const itemsCachedForUserId = getUserIdForCachedItems()
  const lastFetchDate = getLastItemFetchDate()
  const thisFetchDate = new Date()
  const state = getState()
  const canSeeAllItems = currentUserCanSeeAllItems(state)
  const currentUserId = state.auth.user?.uid || null

  let shouldUseCachedItems =
    itemsCachedForUserId === currentUserId &&
    Object.keys(getCachedItems()).length > 0

  console.log(
    'itemsCachedForUserId',
    itemsCachedForUserId,
    '/ current user',
    currentUserId,
  )
  console.log('shouldUseCachedItems', shouldUseCachedItems)
  console.log('canSeeAllItems', canSeeAllItems)

  let eligibilityStatusUpdatedAt
  if (
    lastFetchDate &&
    (eligibilityStatusUpdatedAt =
      state.auth.user?.eligibilityStatusUpdatedAt) &&
    eligibilityStatusUpdatedAt > lastFetchDate
  ) {
    console.debug('eligibility has changed since last fetch, clear cache')
    // User may have stopped being a patron since last fetching
    // so clear the cache and re-fetch all available items
    shouldUseCachedItems = false
    clearCachedItems()
  }

  const itemCollection = collection(db, 'items')
  const queries: Query[] = []
  if (canSeeAllItems) {
    queries.push(itemCollection)
  } else {
    queries.push(query(itemCollection, where('free', '==', true)))
    if (currentUserHasBook1Access(state)) {
      queries.push(
        query(
          itemCollection,
          where('free', '==', false),
          where('bookAccessCodeGrant', '==', ACCESS_CODE_GRANTS.BOOK_1),
        ),
      )
    }
    if (currentUserHasBook2Access(state)) {
      queries.push(
        query(
          itemCollection,
          where('free', '==', false),
          where('bookAccessCodeGrant', '==', ACCESS_CODE_GRANTS.BOOK_2),
        ),
      )
    }
  }

  const fetchedMap: GriffonItemMap = {}

  for (let q of queries) {
    if (shouldUseCachedItems && lastFetchDate)
      q = query(q, where('updatedAt', '>', Timestamp.fromDate(lastFetchDate)))

    const docs = (await getDocs(q)).docs
    docs.forEach(d => {
      const {updatedAt, ...itemData} = d.data()
      fetchedMap[d.id] = {...itemData, id: d.id} as GriffonItem
    })
  }

  const newItemCount = Object.keys(fetchedMap).length
  console.debug(
    `Fetched ${newItemCount} new items since last fetch ${lastFetchDate}`,
  )

  if (Object.keys(fetchedMap).length > 0) {
    console.warn(`Fetched ${newItemCount} items from Firestore!`)
  }

  // Merge fetched results with cached results
  const resultingMap = {
    ...getCachedItems(),
    ...fetchedMap,
  }

  const results = Object.values(resultingMap)
  dispatch(actions.setSaddlebagContents(results))
  cacheItems(resultingMap, thisFetchDate, currentUserId)

  const totalItemCount = (await getDoc(doc(db, '/meta/items'))).data()?.count
  if (typeof totalItemCount === 'number') {
    dispatch(actions.setTotalItemCount(totalItemCount))
  }

  return results
}

export const saveNewItem = (
  item: UnsavedGriffonItem,
): MyThunkAction<Promise<GriffonDatabaseItem | null>> => async dispatch => {
  dispatch(actions.savingItem(true))
  const trimmedItem: DocumentData = {}
  for (const [key, value] of Object.entries(item)) {
    if (value !== undefined) {
      trimmedItem[key] = value
    }
  }

  let newItem: GriffonDatabaseItem | null = null

  try {
    const newDoc = await addDoc(collection(db, 'items'), trimmedItem)
    newItem = {...item, id: newDoc.id}
  } catch (e) {
    window.alert(e)
    console.error(e)
  }

  if (newItem) {
    dispatch(actions.addSaddlebagItem(newItem))
  }
  dispatch(actions.savingItem(false))

  return newItem
}

export const saveExistingItem = (
  item: GriffonDatabaseItem,
): MyThunkAction<Promise<boolean>> => async dispatch => {
  dispatch(actions.savingItem(true))
  const {id, ...itemWithoutId} = item
  const itemDoc = doc(db, 'items', id)
  let success = false

  try {
    await updateDoc(itemDoc, {...itemWithoutId})
    success = true
    dispatch(actions.updateSaddlebagItem(item))
  } catch (e) {
    window.alert(e)
    console.error(e)
  }

  dispatch(actions.savingItem(false))
  return success
}

export const deleteItem = (
  item: GriffonDatabaseItem,
): MyThunkAction<Promise<boolean>> => async dispatch => {
  dispatch(actions.savingItem(true))

  let success = false
  try {
    await deleteDoc(doc(collection(db, 'items'), item.id))
    dispatch(actions.removeItem(item))
    success = true
  } catch (e) {
    console.error(e)
  }

  dispatch(actions.savingItem(false))
  return success
}

/**
 * @returns URL of uploaded image
 * @param file
 * @param contentType
 */
export const uploadImage = (
  file: File,
): MyThunkAction<Promise<string>> => async () => {
  const id = shortid()

  if (file.type !== 'image/png') {
    throw new Error('must upload a PNG')
  }

  const fileRef = storageRef(storage, `images/${id}/original.png`)
  await uploadBytes(fileRef, file, {customMetadata: {free: 'false'}})

  return id
}

const saveNewBundle = async (
  userId: string,
  bundle: MaybeUnsaved<ItemBundle>,
) => {
  const {id: bundleId, ...bundleData} = bundle
  const bundleCollection = collection(db, 'users', userId, 'bundles')

  if (bundleId) throw Error('stash already has ID')

  const bundleDoc = doc(bundleCollection)
  await setDoc(bundleDoc, bundleData)
  return bundleDoc.id
}

const updateExistingBundle = async (
  userId: string,
  bundleId: string,
  updateData: DocumentData,
) => {
  const bundleCollection = collection(db, 'users', userId, 'bundles')

  const bundleDoc = doc(bundleCollection, bundleId)
  await updateDoc(bundleDoc, updateData)
  return bundleId
}

export const renameBundle = (
  bundle: ItemBundle,
  name: string,
): MyThunkAction<Promise<void>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return
  }

  await updateExistingBundle(user.uid, bundle.id, {name})

  dispatch(actions.setBundleName({bundleId: bundle.id, name}))
}

export const updateStashItems = (
  bundle: ItemBundle,
  items: string[],
): MyThunkAction<Promise<void>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return
  }

  console.debug(`updating stash ${bundle.name} with ${items.length} items`)

  await updateExistingBundle(user.uid, bundle.id, {items})

  dispatch(actions.setBundleItems({bundleId: bundle.id, items}))
}

export const createBundleWithName = (
  name: string,
): MyThunkAction<Promise<ItemBundle | null>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return null
  }

  const bundle = {items: [], name}
  const bundleId = await saveNewBundle(user.uid, bundle)
  const savedBundle = {...bundle, id: bundleId}
  dispatch(actions.addBundle(savedBundle))
  return savedBundle
}

export const deleteBundle = (
  bundle: ItemBundle,
): MyThunkAction<Promise<void>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return
  }

  const bundleDoc = doc(db, 'users', user.uid, 'bundles', bundle.id)
  await deleteDoc(bundleDoc)
  dispatch(actions.removeBundle(bundle))
}

export const duplicateBundle = (
  bundle: ItemBundle,
): MyThunkAction<Promise<ItemBundle | null>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return null
  }

  const newBundle: MaybeUnsaved<ItemBundle> = {
    ...bundle,
    id: undefined,
    name: generateCopyName(bundle.name),
  }

  const newBundleId = await saveNewBundle(user.uid, newBundle)

  const savedNewBundle = {
    ...newBundle,
    id: newBundleId,
  }

  dispatch(actions.addBundle(savedNewBundle))
  return savedNewBundle
}

export const addItemToBundle = (
  itemId: string,
  bundleId?: string,
): MyThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return false
  }

  if (!bundleId) {
    const selectedBundleId = getState().selectedBundleId
    if (!selectedBundleId) {
      console.error('no selected bundle or provided bundle id')
      return false
    }
    bundleId = selectedBundleId
  }

  dispatch(actions.addItemToBundle({bundleId, itemId}))

  const bundle = bundlesById(getState())[bundleId]
  if (!bundle) {
    return false
  }

  const bundleItems = bundle.items

  try {
    await updateExistingBundle(user.uid, bundle.id, {
      items: bundleItems.map(i => doc(db, 'items', i)),
    })
  } catch (e) {
    console.error(e)
    dispatch(actions.removeItemFromBundle({bundleId, itemId}))
    return false
  }

  return true
}

export const removeItemFromBundle = (
  itemId: string,
  bundleId?: string,
): MyThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return false
  }

  if (!bundleId) {
    const selectedBundleId = getState().selectedBundleId
    if (!selectedBundleId) {
      console.error('no selected bundle or provided bundle id')
      return false
    }
    bundleId = selectedBundleId
  }

  dispatch(actions.removeItemFromBundle({bundleId, itemId}))

  const bundle = bundlesById(getState())[bundleId]
  if (!bundle) {
    return false
  }

  const bundleItems = bundle.items

  try {
    await updateExistingBundle(user.uid, bundle.id, {
      items: bundleItems.map(i => doc(db, 'items', i)),
    })
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

export const setBundleName = (
  name: string,
  bundleId?: string,
): MyThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const user = getState().auth.user
  if (!user) {
    console.error('not logged in')
    return false
  }

  if (!bundleId) {
    const selectedBundleId = getState().selectedBundleId
    if (!selectedBundleId) {
      console.error('no selected bundle or provided bundle id')
      return false
    }
    bundleId = selectedBundleId
  }
  const bundle = bundlesById(getState())[bundleId]
  if (!bundle) {
    return false
  }

  try {
    await updateExistingBundle(user.uid, bundle.id, {name})
    dispatch(actions.setBundleName({bundleId, name}))
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

export const setItemFave = (
  item: GriffonItem,
  fave: boolean,
): MyThunkAction<Promise<void>> => async (dispatch, getState) => {
  const faveBundle = getMyFaveBundle(getState())
  if (!faveBundle?.id) {
    console.error('no fave bundle found!')
    return
  }

  if (fave) {
    await dispatch(addItemToBundle(item.id, faveBundle.id))
  } else {
    await dispatch(removeItemFromBundle(item.id, faveBundle.id))
  }
}

export const loadMyBundles = (): MyThunkAction<Promise<ItemBundle[]>> => async (
  dispatch,
  getState,
) => {
  const state = getState()
  const user = state.auth.user

  if (!user) {
    dispatch(actions.setMyBundles([]))
    return []
  }

  const bundleFromDoc = (doc: QueryDocumentSnapshot): ItemBundle => ({
    id: doc.id,
    name: doc.data().name,
    items: (doc.data().items || []).map((itemRef: string | DocumentReference) =>
      typeof itemRef === 'string' ? itemRef : itemRef.id,
    ),
    type: doc.data().type || null,
  })

  let bundles: ItemBundle[] = []

  try {
    bundles = (
      await getDocs(collection(db, 'users', user.uid, 'bundles'))
    ).docs.map(bundleFromDoc)
    console.warn(`Fetched ${bundles.length} bundles from Firestore!`)
  } catch (e) {
    console.error(e)
  }

  dispatch(actions.setMyBundles(bundles))
  return bundles
}

export const linkPatreonAccount = (): MyThunkAction<Promise<
  void
>> => async () => {
  const functions = getFunctions(myFirebase)
  const getPatreonAuthorizeUrl = httpsCallable(
    functions,
    'getPatreonAuthorizeUrl',
  )
  const authorizeUrl = (await getPatreonAuthorizeUrl()).data as string
  window.location.assign(authorizeUrl)
}

export const handleAccessCodeClaimSuccess = (): MyThunkAction<Promise<
  void
>> => async (dispatch, getState) => {
  const currentUser = getState().auth.user
  if (!currentUser) return

  const refreshedUser = await getUserFromDB(currentUser)
  clearCachedItems()
  dispatch(actions.loginSucceeded(refreshedUser))
}

export const setAndRememberDetailMode = (
  detailMode: DetailMode,
): MyThunkAction<Promise<void>> => async dispatch => {
  window.localStorage.setItem('detailMode', detailMode)
  dispatch(actions.uiSetDetailMode(detailMode))
}

export const setAndRememberFreeColumnVisible = (
  visible: boolean,
): MyThunkAction<Promise<void>> => async dispatch => {
  window.localStorage.setItem('freeColumnVisible', visible ? 'y' : '')
  dispatch(actions.uiSetFreeColumnVisible(visible))
}

export const setAndRememberDateFormat = (
  format: DateFormat,
): MyThunkAction<Promise<void>> => async dispatch => {
  window.localStorage.setItem('ui.dateFormat', format)
  dispatch(actions.uiSetDateFormat(format))
}
