import lscache from 'lscache'
import moize from 'moize'

import {storage} from './firebase/firebase'
import {ref as storageRef, getDownloadURL} from 'firebase/storage'

export const getCachedImageUrl = (imageId: string, size: string) =>
  imageId ? lscache.get(`image:${imageId}:${size}`) : null

export const getImageUrl = moize({isPromise: true})(
  async (id: string, size: string) => {
    if (!id) return null

    const key = `image:${id}:${size}`
    const cached: string = lscache.get(key)
    if (cached) return cached

    const fileRef = storageRef(storage, `images/${id}/${size}.png`)
    const url: string = await getDownloadURL(fileRef)
    lscache.set(key, url, 60)
    return url
  },
)
