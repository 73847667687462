import {applyMiddleware, compose, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import logger from 'redux-logger'
import thunkMiddleware, {ThunkDispatch, ThunkMiddleware} from 'redux-thunk'

import {AllActions, actions} from '../actions/standard'
import reducer from '../reducers'
import {ThenArg} from '../types'
import {RootState as RootStateType} from './types'

const logRedux = process.env.NODE_ENV !== 'production'

type DispatchExt = ThunkDispatch<RootState, undefined, AllActions>
type Ext = {dispatch: DispatchExt}

export const configureStore = (persistedState?: RootState) => {
  const appliedThunk = applyMiddleware(
    thunkMiddleware as ThunkMiddleware<RootState, AllActions>,
  )
  const enhancers = logRedux
    ? composeWithDevTools<Ext, Record<string, unknown>>(
        appliedThunk,
        applyMiddleware<DispatchExt>(logger),
      )
    : compose(appliedThunk)

  const store = createStore<
    RootState,
    AllActions,
    {dispatch: ThunkDispatch<RootState, undefined, AllActions>},
    Record<string, unknown>
  >(reducer, persistedState, enhancers)

  const listMode = window.localStorage.getItem('listMode')
  if (listMode === 'list' || listMode === 'grid')
    store.dispatch(actions.uiSetListMode(listMode, 'home'))

  const detailMode = window.localStorage.getItem('detailMode')
  if (detailMode === 'modal' || detailMode === 'card')
    store.dispatch(actions.uiSetDetailMode(detailMode))

  const dateFormat = window.localStorage.getItem('ui.dateFormat')
  if (dateFormat === 'long' || dateFormat === 'short')
    store.dispatch(actions.uiSetDateFormat(dateFormat))

  const freeColumnVisible = Boolean(
    window.localStorage.getItem('freeColumnVisible'),
  )
  store.dispatch(actions.uiSetFreeColumnVisible(freeColumnVisible))

  return store
}

export type StoreDispatch = ThenArg<
  ReturnType<typeof configureStore>
>['dispatch']

export type RootState = RootStateType
