import React, {ChangeEventHandler, ChangeEvent, FormEvent} from 'react'
import * as luxon from 'luxon'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faClipboard,
  faClipboardCheck,
  faCog,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

import {getAllSubtypes} from '../../selectors'
import {
  ItemType,
  GriffonItem,
  UnsavedGriffonItem,
  RootState,
  GriffonDatabaseItem,
} from '../../store/types'
import {StoreDispatch} from '../../store'
import {connect, MapStateToProps} from 'react-redux'
import {saveNewItem, saveExistingItem, deleteItem} from '../../actions/thunks'
import ImageUpload from './ImageUpload'
import SlightlyRichTextEditor from './SlightlyRichTextEditor'
import ItemDescription from '../ItemDescription'
import {itemMetaString} from '../../utils'
import ScrollToTopOnMount from '../ScrollToTopOnMount'
import {storage} from '../../firebase/firebase'
import {getDownloadURL, ref} from 'firebase/storage'

interface AvraeExport {
  name: string
  meta: string
  desc: string
  image?: string
}

const avraeJsonForItem = (
  item: Omit<GriffonDatabaseItem, 'id'>,
  imageUrl: string | null,
) => {
  const avraeObj: AvraeExport = {
    name: item.name,
    meta: itemMetaString(item),
    desc:
      item.description +
      `

___

**Art.**
${item.patreonArtUrl}

**Cards.**
${item.patreonCardUrl}`,
  }

  if (imageUrl) {
    avraeObj.image = imageUrl
  }

  return JSON.stringify(avraeObj)
}

interface StateProps {
  editingItem?: GriffonDatabaseItem
  subtypeOptions: string[]
}

type Props = RouteComponentProps<{id: string}> &
  StateProps & {
    dispatch: StoreDispatch
  }

interface State {
  useDefaultRarityText: boolean
  fields: UnsavedGriffonItem | GriffonDatabaseItem
  saving: boolean
  savingError: boolean
  avraeImageUrl: string | null
  avraeImageLoading: boolean
  avraeJsonCopied: string
}

const mapStateToProps: MapStateToProps<
  StateProps,
  RouteComponentProps<{id: string}>,
  RootState
> = (state, ownProps) => {
  let editingItem: GriffonDatabaseItem | undefined = undefined
  const paramId = ownProps.match.params.id
  if (paramId && state.contents)
    editingItem = state.contents.find(i => i.id === ownProps.match.params.id)

  return {
    editingItem,
    subtypeOptions: getAllSubtypes(state),
  }
}

type ControlChangeHandler = ChangeEventHandler<HTMLInputElement>
type CheckChangeHandler = ChangeEventHandler<HTMLInputElement>

const _stringToSelectOption = <T extends string>(s: T) => ({label: s, value: s})

class ItemForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const initialFields:
      | UnsavedGriffonItem
      | GriffonItem = props.editingItem || {
      name: '',
      free: false,
      attunement: false,
      description: '',
      imageIds: [],
      itemType: 'Wondrous item',
      rarities: [],
      rarity: '',
      flavour: '',
      tags: [],
      dateMonth: new Date().getMonth(),
      dateYear: new Date().getFullYear(),
      dateDay: new Date().getDate(),
    }
    this.state = {
      fields: initialFields,
      useDefaultRarityText: true,
      saving: false,
      savingError: false,
      avraeImageUrl: null,
      avraeImageLoading: false,
      avraeJsonCopied: '',
    }
  }

  componentDidMount() {
    this.updateAvraeImageUrl()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.editingItem &&
      prevProps.editingItem !== this.props.editingItem
    ) {
      this.setState({fields: this.props.editingItem})
    }

    if (prevState.fields.imageIds?.[0] !== this.state.fields.imageIds?.[0]) {
      this.updateAvraeImageUrl()
    }
  }

  updateAvraeImageUrl = async () => {
    const imageId = this.state.fields.imageIds?.[0]
    if (imageId) {
      this.setState({avraeImageLoading: true})
      try {
        const fileRef = ref(storage, `images/${imageId}/medium.png`)
        const imageUrl = await getDownloadURL(fileRef)
        this.setState({avraeImageUrl: imageUrl, avraeImageLoading: false})
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((e as any).code === 'storage/object-not-found') {
          setTimeout(this.updateAvraeImageUrl, 1000)
        }
      }
    } else {
      this.setState({avraeImageUrl: null, avraeImageLoading: false})
    }
  }

  handleSubmit = (e?: FormEvent) => {
    e?.preventDefault()
    this.setState({saving: true})

    if (this.props.editingItem) {
      this.props
        .dispatch(
          saveExistingItem({
            ...this.state.fields,
            id: this.props.editingItem.id,
          }),
        )
        .then(success => {
          this.setState({saving: false, savingError: !success})
          this.props.history.goBack()
        })
      return
    }

    this.props.dispatch(saveNewItem(this.state.fields)).then(res => {
      this.setState({saving: false, savingError: !res})
      this.props.history.push('/')
    })
  }

  handleDelete = () => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${this.props.editingItem?.name}?`,
      )
    ) {
      return
    }

    if (this.props.editingItem) {
      this.setState({saving: true})

      this.props.dispatch(deleteItem(this.props.editingItem)).then(success => {
        this.setState({saving: false, savingError: !success})
        this.props.history.goBack()
      })
    }
  }

  setFields = (delta: Partial<GriffonItem>) =>
    this.setState({
      fields: {...this.state.fields, ...delta},
    })

  getDefaultRarityText = (rarities: GriffonItem['rarities']) =>
    rarities.length === 0
      ? ''
      : rarities.length === 1
      ? rarities[0]
      : 'rarity varies'

  changeName: ControlChangeHandler = e => this.setFields({name: e.target.value})
  changeArtUrl: ControlChangeHandler = e =>
    this.setFields({patreonArtUrl: e.target.value})
  changeCardUrl: ControlChangeHandler = e =>
    this.setFields({patreonCardUrl: e.target.value})
  changeRarityText: ControlChangeHandler = e => {
    const useDefaultRarityText = !e.target.value
    this.setState({
      fields: {
        ...this.state.fields,
        rarity: useDefaultRarityText
          ? this.getDefaultRarityText(this.state.fields.rarities)
          : e.target.value,
      },
      useDefaultRarityText,
    })
  }
  changeRarity = (
    rarity: GriffonItem['rarities'][number],
    selected: boolean,
  ) => {
    const raritySet = new Set(this.state.fields.rarities)

    if (selected) raritySet.add(rarity)
    else raritySet.delete(rarity)

    const rarities = Array.from(raritySet)
    const delta: Partial<GriffonItem> = {rarities}

    if (this.state.useDefaultRarityText) {
      delta.rarity = this.getDefaultRarityText(rarities)
    }

    this.setFields(delta)
  }
  changeItemType = (v: ItemType) => this.setFields({itemType: v})
  changeItemSubtype = (v: string) => this.setFields({itemSubtype: v})
  changeDescription: ChangeEventHandler<HTMLTextAreaElement> = e =>
    this.setFields({description: e.target.value})
  changeFlavour: ChangeEventHandler<HTMLTextAreaElement> = e =>
    this.setFields({flavour: e.target.value})
  changeIsFree: CheckChangeHandler = e =>
    this.setFields({free: e.target.checked})
  changeRequiresAttunement: CheckChangeHandler = e =>
    this.setFields({attunement: e.target.checked})
  changeAttunementDetail: ControlChangeHandler = e =>
    this.setFields({attunement: e.target.value || true})
  changeSignificance = (
    significance: 'major' | 'minor' | '',
  ): CheckChangeHandler => e => {
    if (e.target.checked) this.setFields({significance})
  }
  changeMonth = (month: number) => this.setFields({dateMonth: month})
  changeYear: ControlChangeHandler = e =>
    e.target.value && this.setFields({dateYear: parseInt(e.target.value, 10)})
  changeDate: ControlChangeHandler = e => {
    const dateNumber = e.currentTarget.valueAsNumber
    const date = new Date(dateNumber)
    this.setFields({
      dateYear: date.getUTCFullYear(),
      dateMonth: date.getUTCMonth(),
      dateDay: date.getUTCDate(),
    })
  }
  setImage = ({imageId}: {imageId: string}) => {
    this.setFields({
      imageIds: [imageId],
    })
  }

  // components
  form = () => {
    const {editingItem, subtypeOptions} = this.props
    const {fields} = this.state

    return (
      <Col lg={6}>
        <Form>
          <h1>{editingItem ? `Edit ${editingItem.name}` : 'Add New Item'}</h1>

          <ImageUpload
            existingId={editingItem?.imageIds?.[0]}
            imageId={fields.imageIds?.[0]}
            onUpload={this.setImage}
          />

          <Form.Group controlId="name-input">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={fields.name || ''}
              onChange={this.changeName}
              required
            />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} controlId="item-type-input">
              <Form.Label>Item type</Form.Label>
              <Select<{label: ItemType; value: ItemType}>
                inputId="item-type-input"
                onChange={v =>
                  v && 'value' in v && this.changeItemType(v.value)
                }
                value={_stringToSelectOption(fields.itemType)}
                options={([
                  'Armor',
                  'Potion',
                  'Ring',
                  'Rod',
                  'Scroll',
                  'Staff',
                  'Wand',
                  'Weapon',
                  'Wondrous item',
                ] as const).map(_stringToSelectOption)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="item-subtype-input">
              <Form.Label>Subtype</Form.Label>
              <CreatableSelect
                inputId="item-subtype-input"
                isValidNewOption={input => !!input}
                createOptionPosition="first"
                onChange={v =>
                  v && 'value' in v && this.changeItemSubtype(v.value)
                }
                value={
                  fields.itemSubtype
                    ? _stringToSelectOption(fields.itemSubtype)
                    : null
                }
                options={subtypeOptions.map(_stringToSelectOption)}
              />
              {/* <Form.Control
                    type="text"
                    value={fields.itemSubtype || ''}
                    onChange={this.changeItemSubtype}
                  /> */}
            </Form.Group>
          </Form.Row>

          <Form.Group>
            {([
              'common',
              'uncommon',
              'rare',
              'very rare',
              'legendary',
              'artifact',
            ] as GriffonItem['rarities']).map(rarity => (
              <Form.Check
                key={rarity}
                inline
                label={rarity}
                type="checkbox"
                id={`rarity-checkbox-${rarity}`}
                checked={fields.rarities.includes(rarity)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.changeRarity(rarity, e.target.checked)
                }
              />
            ))}
          </Form.Group>

          <Form.Group controlId="rarity-input">
            <Form.Control
              type="text"
              value={fields.rarity}
              onChange={this.changeRarityText}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Form.Check
                type="checkbox"
                id="requires-attunement-checkbox"
                label="Requires attunement"
                checked={!!fields.attunement}
                onChange={this.changeRequiresAttunement}
              />
            </Form.Label>
            <Form.Control
              type="text"
              value={
                (typeof fields.attunement === 'string' && fields.attunement) ||
                ''
              }
              onChange={this.changeAttunementDetail}
              placeholder="Additional attunement requirements"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="description-input">Description</Form.Label>
            <SlightlyRichTextEditor
              id="description-input"
              className="form-control"
              maxRows={10}
              value={fields.description}
              onChange={this.changeDescription}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="flavour-input">Flavor text</Form.Label>
            <SlightlyRichTextEditor
              id="flavour-input"
              className="form-control"
              maxRows={10}
              value={fields.flavour || ''}
              onChange={this.changeFlavour}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Subrarity</Form.Label>
            <Form.Check
              type="radio"
              name="significance"
              id="significance-minor-radio"
              label="Minor item"
              checked={fields.significance === 'minor'}
              onChange={this.changeSignificance('minor')}
            />
            <Form.Check
              type="radio"
              name="significance"
              id="significance-major-radio"
              label="Major item"
              checked={fields.significance === 'major'}
              onChange={this.changeSignificance('major')}
            />
          </Form.Group>

          <Form.Group>
            <Card>
              <Card.Body>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="available-free-checkbox"
                    label="Available for free"
                    checked={fields.free}
                    onChange={this.changeIsFree}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor="date-input">Date added</Form.Label>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        value={
                          fields.dateYear !== undefined &&
                          fields.dateMonth !== undefined
                            ? luxon.DateTime.utc(
                                fields.dateYear,
                                fields.dateMonth + 1,
                                fields.dateDay,
                              ).toISODate()
                            : ''
                        }
                        type="date"
                        onChange={this.changeDate}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group controlId="patreon-art-url-input">
                  <Form.Label>Patreon art URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={fields.patreonArtUrl || ''}
                    onChange={this.changeArtUrl}
                  />
                </Form.Group>
                <Form.Group controlId="patreon-card-url-input">
                  <Form.Label>Patreon cards URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={fields.patreonCardUrl || ''}
                    onChange={this.changeCardUrl}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Form.Group>
        </Form>
      </Col>
    )
  }
  preview = () => {
    const {editingItem} = this.props
    const {fields, saving, avraeImageUrl} = this.state

    return (
      <Col lg={6}>
        <Form.Group>
          <Card>
            <Card.Body>
              <h3>
                {fields.name}
                {fields.free && (
                  <>
                    {' '}
                    <Badge variant="success">Free!</Badge>
                  </>
                )}
              </h3>
              <p>
                <em>{itemMetaString(fields)}</em>
              </p>
              {fields.description && (
                <>
                  <br />
                  <ItemDescription markup={fields.description} />
                </>
              )}
              {fields.flavour && (
                <>
                  <br />
                  <ItemDescription flavour markup={fields.flavour} />
                </>
              )}

              {(fields.patreonArtUrl || fields.patreonCardUrl) && <hr />}
              {fields.patreonArtUrl && (
                <p>
                  <em>
                    <strong>Art.</strong>
                  </em>
                  <br />
                  <a
                    onClick={e => e.preventDefault()}
                    href={fields.patreonArtUrl}
                  >
                    {fields.patreonArtUrl}{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </p>
              )}
              {fields.patreonCardUrl && (
                <p>
                  <em>
                    <strong>Cards.</strong>
                  </em>
                  <br />
                  <a
                    onClick={e => e.preventDefault()}
                    href={fields.patreonCardUrl}
                  >
                    {fields.patreonCardUrl}{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </p>
              )}
            </Card.Body>
          </Card>
        </Form.Group>
        <Form.Group>
          <Button
            variant="primary"
            disabled={saving}
            onClick={this.handleSubmit}
          >
            Save
          </Button>

          <CopyToClipboard
            text={avraeJsonForItem(fields, avraeImageUrl)}
            onCopy={(text, success) => {
              this.setState({avraeJsonCopied: text})
              if (!success) {
                alert('Copy failed!')
                return
              }
              this.handleSubmit()
            }}
          >
            <Button
              disabled={saving || this.state.avraeImageLoading}
              style={{marginLeft: '1rem'}}
            >
              {this.state.avraeImageLoading ? (
                <>
                  Fetching image&hellip; &nbsp;
                  <FontAwesomeIcon icon={faCog} spin />
                </>
              ) : this.state.avraeJsonCopied ===
                avraeJsonForItem(fields, avraeImageUrl) ? (
                <>
                  JSON copied &nbsp;
                  <FontAwesomeIcon icon={faClipboardCheck} />
                </>
              ) : (
                <>
                  Save and Copy JSON &nbsp;
                  <FontAwesomeIcon icon={faClipboard} />
                </>
              )}
            </Button>
          </CopyToClipboard>
          {/* TODO: re-enable when soft-delete is implemented */}
          {false && editingItem && (
            <Button
              variant="danger"
              disabled={saving}
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          )}
        </Form.Group>

        <hr />

        <h5 style={{display: 'flex', alignItems: 'center'}}>
          Avrae import
          <CopyToClipboard
            text={avraeJsonForItem(fields, avraeImageUrl)}
            onCopy={text => {
              this.setState({avraeJsonCopied: text})
            }}
          >
            <Button
              disabled={this.state.avraeImageLoading}
              variant="link"
              style={{padding: '0 0.4rem'}}
              title="Copy to clipboard"
            >
              {this.state.avraeImageLoading ? (
                <FontAwesomeIcon icon={faCog} spin />
              ) : this.state.avraeJsonCopied ===
                avraeJsonForItem(fields, avraeImageUrl) ? (
                <FontAwesomeIcon icon={faClipboardCheck} />
              ) : (
                <FontAwesomeIcon icon={faClipboard} />
              )}
            </Button>
          </CopyToClipboard>
        </h5>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={2}
            style={{fontFamily: 'monospace', wordBreak: 'break-all'}}
            value={avraeJsonForItem(fields, avraeImageUrl)}
            readOnly
          />
        </Form.Group>
        <p style={{fontSize: 'smaller'}}>
          Copy and paste into Avrae (&ldquo;Import from JSON&rdquo;).
        </p>
      </Col>
    )
  }

  render() {
    return (
      <Container>
        <ScrollToTopOnMount />
        <br />
        <Row>
          {this.form()}
          {this.preview()}
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(mapStateToProps)(ItemForm))
