import React, {useState, forwardRef, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import classnames from 'classnames'

import Overlay, {OverlayProps} from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import FormControl from 'react-bootstrap/FormControl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faCheckSquare, faCheck} from '@fortawesome/free-solid-svg-icons'
import {faSquare} from '@fortawesome/free-regular-svg-icons'

import {thunks} from '../../actions'
import {RootState, ItemBundle} from '../../store/types'
import {StoreDispatch} from '../../store'

import styles from './AddToStashTooltip.module.scss'
import {
  alphabeticalMyBundles,
  getSelectedItemBundles,
  selectedItem,
} from '../../selectors'

const _StashListItem: React.RefForwardingComponent<
  HTMLDivElement,
  {
    stash: ItemBundle
    selected: boolean
    onClick: () => void
  }
> = ({stash, selected, onClick}, ref) => (
  <div
    ref={ref}
    className={styles.AddToStashTooltip__stashlist__item}
    onClick={onClick}
  >
    <span className={styles.AddToStashTooltip__stashlist__item__name}>
      {stash.name}
    </span>
    {selected ? (
      <FontAwesomeIcon icon={faCheckSquare} className="text-primary" />
    ) : (
      <FontAwesomeIcon icon={faSquare} />
    )}
  </div>
)

const StashListItem = forwardRef(_StashListItem)

type CustomOverlayProps = Pick<
  OverlayProps,
  'show' | 'target' | 'onHide' | 'placement' | 'container'
>

export interface AddToStashMenuProps {
  stashes: ItemBundle[]
  selectedStashes: ItemBundle[]
  addToStash: (stash: ItemBundle) => void
  removeFromStash: (stash: ItemBundle) => void
  createStash: (name: string) => Promise<ItemBundle | null>
}

const mapStateToProps = (state: RootState) => ({
  stashes: alphabeticalMyBundles(state),
  selectedStashes: getSelectedItemBundles(state),
  selectedItem: selectedItem(state),
})
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  dispatch,
  createStash: (name: string) => dispatch(thunks.createBundleWithName(name)),
})

const AddToStashTooltip: React.FC<AddToStashMenuProps &
  CustomOverlayProps> = props => {
  const {
    stashes,
    selectedStashes,
    addToStash,
    removeFromStash,
    createStash,
    onHide,
  } = props
  const [newlyAddedStashId, setNewlyAddedStashId] = useState<string | null>(
    null,
  )
  const [newStashName, setNewStashName] = useState('')
  const [creatingStash, setCreatingStash] = useState(false)
  const stashContainerRef = useRef<HTMLDivElement>(null)
  const newlyAddedStashRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const elToScrollTo = newlyAddedStashRef?.current
    const scrollContainer = stashContainerRef?.current
    if (newlyAddedStashId && elToScrollTo && scrollContainer) {
      scrollContainer.scrollTo(
        0,
        elToScrollTo.offsetTop - scrollContainer.offsetHeight / 2,
      )
      setNewlyAddedStashId(null)
    }
  }, [newlyAddedStashId, newlyAddedStashRef, stashContainerRef])

  return (
    <Overlay
      onExited={() => {
        setCreatingStash(false)
        setNewStashName('')
      }}
      onHide={onHide}
      rootClose
      show={props.show}
      target={props.target}
      placement={props.placement}
      container={props.container}
    >
      <Tooltip
        className={classnames('tooltip-white', styles.AddToStashTooltip)}
        id="add-to-stash-menu"
      >
        <div
          ref={stashContainerRef}
          className={styles.AddToStashTooltip__stashlist}
        >
          {stashes.map(s => {
            const selected = selectedStashes.includes(s)
            return (
              <StashListItem
                key={s.id}
                stash={s}
                selected={selected}
                onClick={() => (selected ? removeFromStash(s) : addToStash(s))}
                ref={
                  s.id === newlyAddedStashId ? newlyAddedStashRef : undefined
                }
              />
            )
          })}
        </div>
        <ButtonGroup className={styles.AddToStashTooltip__buttongroup}>
          {creatingStash ? (
            <div className={styles.newStashNameContainer}>
              <FormControl
                placeholder="New stash name"
                size="sm"
                autoFocus
                value={newStashName}
                onChange={e => setNewStashName(e.currentTarget.value)}
                style={{fontSize: 16}}
              />
            </div>
          ) : (
            <Button variant="light" onClick={() => setCreatingStash(true)}>
              Create New Stash &nbsp;
              <FontAwesomeIcon icon={faPlus} className="text-primary" />
            </Button>
          )}
          {creatingStash && (
            <Button
              disabled={!newStashName}
              title="Create stash and add item"
              className={classnames({'text-primary': !!newStashName})}
              variant="light"
              onClick={async () => {
                if (!newStashName) {
                  return
                }
                const newStash = await createStash(newStashName)
                if (newStash) {
                  setNewlyAddedStashId(newStash.id)
                  addToStash(newStash)
                }
                setNewStashName('')
                setCreatingStash(false)
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          )}
        </ButtonGroup>
      </Tooltip>
    </Overlay>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ({selectedItem, ...stateProps}, {dispatch, ...dispatchProps}, ownProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      addToStash: (stash: ItemBundle) =>
        selectedItem &&
        dispatch(thunks.addItemToBundle(selectedItem?.id, stash.id)),
      removeFromStash: (stash: ItemBundle) =>
        selectedItem &&
        dispatch(thunks.removeItemFromBundle(selectedItem?.id, stash.id)),
    }
  },
)(AddToStashTooltip)
