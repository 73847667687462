import React from 'react'
import {connect} from 'react-redux'

import {
  DateColumnMode,
  DateFormat,
  GriffonItem,
  RootState,
} from '../store/types'
import {prettyItemDate} from '../utils'

interface Props {
  item: GriffonItem
  dateFormat: DateFormat
  dateColumnMode: DateColumnMode
  none?: string
}

const mapStateToProps = (
  state: RootState,
  ownProps: {dateColumnMode?: DateColumnMode},
) => ({
  dateFormat: state.ui.dateFormat,
  dateColumnMode:
    ownProps.dateColumnMode || state.listViews.home.dateColumnMode,
})

const ItemDateSnippet: React.FC<Props> = ({
  item,
  dateFormat,
  dateColumnMode,
  none,
}) =>
  dateColumnMode === 'card' ? (
    <>{item.cardVolume || none}</>
  ) : dateColumnMode === 'book' ? (
    <>{item.bookVolume || none}</>
  ) : (
    <>{prettyItemDate(item, dateFormat)}</>
  )

export default connect(mapStateToProps)(ItemDateSnippet)
